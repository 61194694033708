import { DocumentNode, gql } from "@apollo/client";
import { FC } from "react";
import { IoCheckmarkCircle } from "swash/Icon";

import { Time } from "@/components/Time";

export type ArticleInitialPublishDateProps = {
  article: { initialFirstPublished: string };
};

export const ArticleInitialPublishDate: FC<ArticleInitialPublishDateProps> & {
  fragments: { article: DocumentNode };
} = ({ article }) => {
  return (
    <div className="flex items-center">
      <IoCheckmarkCircle className="m-1 text-success-bg-strong" size={12} />
      <Time
        className="text-xs font-semibold capitalize text-secondary-on"
        date={article.initialFirstPublished}
        format="ddd D MMMM | HH:mm"
      />
    </div>
  );
};

ArticleInitialPublishDate.fragments = {
  article: gql`
    fragment ArticleInitialPublishDate_article on Article {
      initialFirstPublished
    }
  `,
};
