import { pick, unescape } from "lodash-es";
import moment from "moment";
import { memo, useCallback, useMemo } from "react";
import { createTeleporter } from "react-teleporter";
import { Anchor } from "swash/Anchor";
import { Button } from "swash/Button";
import {
  IoCloudDownloadOutline,
  IoExpand,
  IoInformationCircleOutline,
  IoOpenOutline,
} from "swash/Icon";
import { PanelSection } from "swash/Panel";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { Tab, TabList, TabPanel } from "swash/Tab";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";

import { CopyButton } from "@/components/CopyButton";
import { Image } from "@/components/Image";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { HasPermission } from "@/containers/User";
import { DateField, StringField } from "@/containers/admin/CRUD";
import { agencyFormatter } from "@/services/images";

import { ArticleImageDisclosureTeleporter } from "../article/ArticleImagePreview";
import { ActivitySection } from "./ImageActivityDialog";
import { DeleteImageSection } from "./ImageDelete";
import {
  DialogDisclosure,
  ImageDetailDialog,
  useDialogState,
} from "./ImageDetailDialog";
import { fieldsMap } from "./ImageFieldsMap";
import { IndicatorTeleporter } from "./ImageForm";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogState,
} from "./ImagePreview";
import { RestrictionSection } from "./ImageRestrictions";
import { TypeField } from "./ImageTypeField";
import { ImageUsedIn } from "./ImageUsed";

const ImageDetailDialogButton = memo(({ imageId }) => {
  const dialog = useDialogState();
  return (
    <>
      <Tooltip tooltip="Informations">
        <DialogDisclosure aria-label="Informations" state={dialog}>
          {(disclosureProps) => (
            <Button
              variant="secondary"
              appearance="text"
              iconOnly
              {...disclosureProps}
            >
              <IoInformationCircleOutline />
            </Button>
          )}
        </DialogDisclosure>
      </Tooltip>
      <ImageDetailDialog imageId={imageId} state={dialog} />
    </>
  );
});

const getRestrictionMode = (values) => {
  if (values.restrictionMode === "limited") {
    return values.expiration || values.usageLimit ? "limited" : "default";
  }

  return values.restrictionMode;
};

export const ImageFormContent = ({
  imageId,
  data,
  updateImage,
  editor,
  ...props
}) => {
  const toaster = useToaster();

  const handleSubmit = async (values, form, { dirtyFields = {} }) => {
    if (!data) return;
    const diff = pick(values, Object.keys(dirtyFields));
    try {
      await updateImage({
        variables: {
          input: {
            id: data.image.id,
            ...diff,
            restrictionMode: getRestrictionMode(values),
          },
        },
      });
    } catch (error) {
      toaster.danger("L’édition de l’image a échoué");
    }
  };

  const initialValues = useMemo(() => {
    return {
      type: data?.image.type ?? "",
      caption: data?.image.caption ? unescape(data.image.caption) : "",
      city: data?.image.city ?? "",
      credit: data?.image.credit ?? "",
      keywords: data?.image.keywords ?? "",
      shootingDate: data?.image.shootingDate
        ? moment(data?.image.shootingDate).format("YYYY-MM-DD")
        : null,
      photographer: data?.image.photographer ?? "",
      webAuthorized: data?.image.webAuthorized ?? "",
      printAuthorized: data?.image.printAuthorized ?? "",
      expiration: data?.image.expiration
        ? moment(data.image.expiration).format("YYYY-MM-DD")
        : null,
      restrictionMode: data?.image.restrictionMode ?? null,
      usageLimit: data?.image.usageLimit ?? null,
    };
  }, [data]);

  return (
    <>
      <Form collaborative initialValues={initialValues} onSubmit={handleSubmit}>
        <FormAutoSubmit />
        <FormSubmittingPrompt />
        <IndicatorTeleporter.Source>
          <FormSavingIndicator
            date={data?.image.updatedAt ?? data?.image.createdAt}
          />
        </IndicatorTeleporter.Source>
        <div
          className="relative grid overflow-hidden"
          style={{
            gridTemplateColumns: "minmax(0, 1fr) 424px",
          }}
        >
          <ImageContent data={data} imageId={imageId} {...props} />
          <div
            className="z-10 border-l border-grey-border-light"
            style={{ height: 610 }}
          >
            <TabContent
              data={data}
              imageId={imageId}
              editor={editor}
              {...props}
            />
          </div>
        </div>
      </Form>
      <ArticleContentTeleporter.Source>
        {editor}
      </ArticleContentTeleporter.Source>
    </>
  );
};

const ArticleContentTeleporter = createTeleporter();

const TabContent = ({ editor, tab, ...props }) => {
  if (!editor) {
    return (
      <div className="scrollbar-light h-full overflow-auto p-4">
        <GeneralContent {...props} />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <div className="p-2">
        <TabList state={tab} aria-label="Édition de l’image">
          <Tab state={tab} id="article">
            Pour cet article
          </Tab>
          <Tab state={tab} id="general">
            Général
          </Tab>
        </TabList>
      </div>
      <div className="scrollbar-light overflow-auto">
        <TabPanel state={tab} tabId="article" className="px-4">
          <ArticleContentTeleporter.Target />
        </TabPanel>
        <TabPanel state={tab} tabId="general" className="px-4">
          <GeneralContent {...props} />
        </TabPanel>
      </div>
    </div>
  );
};

const GeneralContent = ({ imageId, data, onDelete }) => {
  return (
    <>
      <InformationSection data={data} />
      <PanelSection className="flex flex-col gap-4">
        <RestrictionSection images={data ? [data?.image] : []} />
      </PanelSection>
      <UsedSection data={data} />
      <ActivitySection
        resource="image"
        resourceId={data?.image.id}
        fieldsMap={fieldsMap}
        title="Activités générales"
      />
      <HasPermission permission="images:full">
        <DeleteImageSection imageId={imageId} onDelete={onDelete} />
      </HasPermission>
    </>
  );
};

const InformationSection = ({ data }) => {
  const disabled = !data;

  return (
    <PanelSection className="flex flex-col gap-4">
      <TypeField
        name={fieldsMap.type.name}
        label={fieldsMap.type.label}
        disabled={disabled}
      />
      <StringField
        name={fieldsMap.caption.name}
        label={fieldsMap.caption.label}
        multiline
        maxRows={6}
        rich
        disabled={disabled}
        placeholder="Saisissez une description."
        scale="base"
      />
      <StringField
        name={fieldsMap.credit.name}
        label={fieldsMap.credit.label}
        rich
        disabled={disabled}
        scale="base"
      />
      <StringField
        name={fieldsMap.photographer.name}
        label={fieldsMap.photographer.label}
        disabled={disabled}
      />
      <DateField
        name={fieldsMap.shootingDate.name}
        label={fieldsMap.shootingDate.label}
        disabled={disabled}
      />
      <StringField
        name={fieldsMap.city.name}
        label={fieldsMap.city.label}
        disabled={disabled}
      />
      <StringField
        name={fieldsMap.keywords.name}
        label={fieldsMap.keywords.label}
        disabled={disabled}
      />
    </PanelSection>
  );
};

const UsedSection = ({ data }) => {
  return (
    <PanelSection>
      <ImageUsedIn node={data?.image} />
    </PanelSection>
  );
};

const ImageContent = ({ data, imageId, preview }) => {
  return (
    <div className="flex flex-col gap-3 bg-grey-bg-light px-4 py-2">
      <TopToolbar data={data} imageId={imageId} preview={preview} />
      <div className="flex grow">
        {data ? <ImagePreview image={data.image} preview={preview} /> : null}
      </div>

      <ImageMetadata data={data} />
    </div>
  );
};

const ImagePreview = ({ image, preview }) => {
  const previewState = useImagePreviewDialogState({ imageId: image.id });
  return (
    <>
      <ImagePreviewDialogDisclosure
        className="w-full"
        title="Prévisualiser l’image"
        {...previewState}
      >
        {(disclosureProps) => (
          <PreviewLink {...disclosureProps}>
            <PreviewLinkContent className="flex h-full grow items-center justify-center bg-grey-bg-light">
              <div
                className="flex justify-center overflow-hidden"
                style={{ maxHeight: 492 }}
              >
                {preview ?? (
                  <Image {...image.fluid} className="object-contain" />
                )}
              </div>
            </PreviewLinkContent>
            <PreviewLinkHovering>
              <IoExpand />
            </PreviewLinkHovering>
          </PreviewLink>
        )}
      </ImagePreviewDialogDisclosure>
      <ImagePreviewDialog {...previewState} />
    </>
  );
};

const TopToolbar = ({ imageId, data, preview }) => {
  return (
    <div className="z-10 flex justify-between py-0.5">
      <div className="flex items-center gap-1 font-accent text-sm">
        <span className="font-semibold">Agence :</span>{" "}
        {agencyFormatter.format(
          data ? data.image.agencies.map((x) => x.label) : [],
        ) || "-"}
      </div>
      <div className="flex gap-2">
        <HasPermission permission="article:image:download">
          <ImageDetailDialogButton imageId={imageId} />
          <Tooltip tooltip="Ouvrir l’image originale">
            <Button
              variant="secondary"
              appearance="text"
              disabled={!data}
              iconOnly
              asChild
            >
              <Anchor href={data ? data.image.url : null} target="_blank">
                <IoOpenOutline />
              </Anchor>
            </Button>
          </Tooltip>
          <Tooltip tooltip="Télécharger l’image originale">
            <Button
              variant="secondary"
              appearance="text"
              disabled={!data}
              iconOnly
              asChild
            >
              <Anchor
                href={data ? `${data.image.url}/download` : null}
                target="_blank"
              >
                <IoCloudDownloadOutline />
              </Anchor>
            </Button>
          </Tooltip>
          <CopyButton
            value={data?.image.siriusUrl}
            title="Copier l’URL de l’image originale"
            scale="md"
            variant="secondary"
          />
        </HasPermission>
        {preview && <ArticleImageDisclosureTeleporter.Target />}
      </div>
    </div>
  );
};

const Metadata = ({ label, value }) => (
  <div className="flex gap-1">
    <span className="font-semibold">{label} :</span> {value || "-"}
  </div>
);

const ImageMetadata = ({ data }) => {
  const renderDimensions = useCallback(() => {
    if (!data) return "-";
    const { ImageWidth, ImageHeight } = data.image.metadata;
    return !ImageWidth || !ImageHeight ? "-" : `${ImageWidth}X${ImageHeight}`;
  }, [data]);

  return (
    <div className="z-10 flex h-8 items-center justify-between text-xs">
      <Metadata label="Poids" value={data?.image.metadata["FileSize"]} />
      <Metadata label="Dimensions" value={renderDimensions()} />
      <Metadata label="Extension" value={data?.image.metadata["FileType"]} />
      <Metadata
        label="Importé le"
        value={moment(data?.image.createdAt).format("DD/MM/YYYY")}
      />
    </div>
  );
};
