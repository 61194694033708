import { Button } from "swash/Button";
import {
  IoEllipsisHorizontal,
  IoHandLeft,
  IoLockClosed,
  IoLockOpen,
} from "swash/Icon";
import { Menu, MenuButton, MenuItem, useMenuStore } from "swash/Menu";
import { Tooltip } from "swash/Tooltip";

import { HasPermission } from "../User";
import { LockerName } from "./Locker";

/**
 * @param {object} props
 * @param {import('react').ReactNode} props.title
 * @param {import('react').ReactNode} props.menu
 * @param {(props: object) => import('react').ReactNode} props.children
 */
function Decorator({ title, menu: menuContent, children }) {
  const menu = useMenuStore({ placement: "bottom-start" });
  return (
    <>
      <Tooltip tooltip={title}>
        <MenuButton
          store={menu}
          aria-label={title}
          render={(disclosureProps) =>
            children({ type: "button", ...disclosureProps })
          }
        />
      </Tooltip>
      {menuContent ? (
        <Menu store={menu} aria-label="Options du verrou">
          {typeof menuContent === "function"
            ? menuContent({ menu })
            : menuContent}
        </Menu>
      ) : null}
    </>
  );
}

/**
 * @typedef RenderProps
 * @property {import('./LockState').LockState['api']} api
 * @property {import('./LockState').LockState['status']} status
 * @property {boolean} [disabled]
 * @property {string} [scale]
 */

/**
 * @param {RenderProps} props
 */
function renderLoadingButton({ scale, appearance }) {
  return (
    <Decorator>
      {(decoProps) => (
        <Button
          {...decoProps}
          scale={scale}
          disabled
          variant="secondary"
          appearance={appearance}
        >
          <IoEllipsisHorizontal />
          Chargement
        </Button>
      )}
    </Decorator>
  );
}

/**
 * @param {RenderProps} props
 */
function renderUnlockedButton({ api, disabled, scale, appearance }) {
  return (
    <Decorator>
      {(decoProps) => (
        <Button
          {...decoProps}
          disabled={disabled}
          scale={scale}
          appearance={appearance}
          onClick={() => {
            api.lock();
          }}
        >
          <IoLockOpen />
          Prendre
        </Button>
      )}
    </Decorator>
  );
}

/**
 * @param {RenderProps} props
 */
function renderLockedByMeButton({ api, disabled, scale, appearance }) {
  return (
    <Decorator title="Relâcher le verrou">
      {(decoProps) => (
        <Button
          {...decoProps}
          disabled={disabled}
          scale={scale}
          appearance={appearance}
          variant="success"
          onClick={() => {
            api.unlock();
          }}
        >
          <IoLockClosed />
          Moi
        </Button>
      )}
    </Decorator>
  );
}

/**
 * @param {RenderProps} props
 */
function renderLockedByMeElsewhereButton({ api, disabled, scale, appearance }) {
  return (
    <Decorator title="Prendre le verrou dans ce contexte">
      {(decoProps) => (
        <Button
          {...decoProps}
          disabled={disabled}
          scale={scale}
          appearance={appearance}
          variant="secondary"
          onClick={() => {
            api.lock();
          }}
        >
          <IoLockClosed />
          Moi (ailleurs)
        </Button>
      )}
    </Decorator>
  );
}

/**
 * @param {object} props
 * @param {import('./LockApi').LockApi} props.api
 */
function renderLockedBySomeoneElseButton({ api, disabled, scale, appearance }) {
  return (
    <Decorator
      menu={({ menu }) => (
        <div className="flex flex-col gap-1">
          <MenuItem
            type="button"
            disabled={disabled}
            pl={1}
            onClick={() => {
              api.requestLock();
              menu.hide();
            }}
          >
            <IoHandLeft />
            Demander
          </MenuItem>
          <HasPermission permission="lock:force">
            <MenuItem
              type="button"
              disabled={disabled}
              pl={1}
              variant="danger"
              onClick={() => {
                api.lock();
                menu.hide();
              }}
            >
              <IoLockOpen />
              Prendre
            </MenuItem>
          </HasPermission>
        </div>
      )}
    >
      {(decoProps) => (
        <Button
          {...decoProps}
          scale={scale}
          disabled={disabled}
          appearance={appearance}
          variant="secondary"
        >
          <IoLockClosed />
          <LockerName user={api.info.locker.user} />
        </Button>
      )}
    </Decorator>
  );
}

const LockStatusRender = {
  loading: renderLoadingButton,
  unlocked: renderUnlockedButton,
  "locked-by-me": renderLockedByMeButton,
  "locked-by-me-elsewhere": renderLockedByMeElsewhereButton,
  "locked-by-someone-else": renderLockedBySomeoneElseButton,
};

/**
 * @param {import('./LockState').LockState & { disabled: undefined | boolean, scale: undefined | string }} props
 */
export function LockButton({ api, status, disabled, scale, appearance }) {
  const render = LockStatusRender[status];
  if (!render) return null;
  return render({ api, disabled, scale, appearance });
}
