import { DocumentNode, gql } from "@apollo/client";
import clsx from "clsx";

import { NamedIcon } from "../components/NamedIcon";

export type ArticleExposureState = "fulfilled" | "selected" | "suggested";

export const ExposureTypeIcon: React.FC<
  {
    label: string;
    icon?: string;
    acronym?: string;
  } & React.SVGProps<SVGSVGElement>
> & {
  fragments: { exposure: DocumentNode };
} = ({ label, icon, acronym, ...props }) => {
  if (!props.height && !props.width) props.width = 20;

  // FIXME: It seems that this icon is missing
  if (icon === "CopyPrint") icon = "PaperCopy";

  return (
    <svg
      viewBox="0 0 20 20"
      aria-label={label}
      {...props}
      className={clsx("-m-px", props.className)}
    >
      {icon ? (
        <NamedIcon name={icon} x={1} y={1} size={18} />
      ) : (
        <LetterIcon y={16}>{acronym}</LetterIcon>
      )}
    </svg>
  );
};
ExposureTypeIcon.fragments = {
  exposure: gql`
    fragment ExposureTypeIcon_exposure on Exposure {
      id
      label
      icon
      acronym
    }
  `,
};

export const ArticleExposureIcon: React.FC<
  {
    label: string;
    icon?: string;
    acronym?: string;
    state: ArticleExposureState;
  } & React.SVGProps<SVGSVGElement>
> & {
  fragments: { exposure: DocumentNode };
} = ({ label, icon, acronym, state, ...props }) => {
  if (!props.height && !props.width) props.width = 20;

  // FIXME: It seems that this icon is missing
  if (icon === "CopyPrint") icon = "PaperCopy";

  return (
    <svg
      viewBox="0 0 20 20"
      aria-label={label}
      {...props}
      className={clsx(
        "-m-px",
        state !== "fulfilled" ? "text-grey-on" : "text-dusk-on",
        props.className,
      )}
    >
      {icon ? (
        <NamedIcon x={(20 - 13) / 2} y={1} size={13} name={icon} />
      ) : (
        <LetterIcon y={14}>{acronym}</LetterIcon>
      )}
      <StateLine state={state} />
    </svg>
  );
};
ArticleExposureIcon.fragments = {
  exposure: gql`
    fragment ArticleExposureIcon_exposure on Exposure {
      id
      label
      icon
      acronym
      enabled
      type
      warnPosteriorArticlePublication
      requiredPriorArticlePublication
    }
  `,
};

const LetterIcon: React.FC<React.SVGProps<SVGTextElement>> = ({
  children,
  ...props
}) => {
  if (typeof children !== "string") return null;
  const isSingleChar = children.length === 1;
  return (
    <text
      x={10}
      width={18}
      textAnchor="middle"
      textLength={isSingleChar ? undefined : 18}
      fontSize="19"
      fontWeight="600"
      fill="currentColor"
      lengthAdjust="spacingAndGlyphs"
      style={{ userSelect: "none", fontFamily: "var(--font-sans)" }}
      {...props}
    >
      {children}
    </text>
  );
};

const StateLine: React.FC<
  {
    state: ArticleExposureState;
  } & (React.SVGProps<SVGGElement> & React.SVGProps<SVGPathElement>)
> = ({ state, ...props }) => {
  switch (state) {
    case "fulfilled":
      // Checked line
      return (
        <g {...props}>
          <path d="M1 17h10l2 2H1V17Z" fill="currentColor" />
          <path
            fill="none"
            stroke="#32AD81"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="m19 16-3 3-2-2"
          />
        </g>
      );
    case "selected":
      // Full line
      return <path d="M1 17h18v2H1z" fill="currentColor" {...props} />;
    case "suggested":
      // Dashed line
      return (
        <path
          d="M1 17h3v2H1zm5 0h3v2H6zm5 0h3v2h-3zm5 0h3v2h-3z"
          fill="currentColor"
          {...props}
        />
      );
  }
};
