// @ts-check
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/** @typedef {{ [key: string]: string }} ResourceMap */

const ResourcesContext =
  /** @type {import('react').Context<string[] | null>} */ (createContext(null));

/**
 * @typedef Callbacks
 * @property {React.Dispatch<React.SetStateAction<ResourceMap>>} setResourceMap
 * @property {(resource: string, listener: import('./LockApi').RequestCompletionListener) => () => void} listenRequestCompletion
 * @property {(resource: string, data: any) => void} emitRequestCompletion
 */

const CallbacksContext =
  /** @type {import('react').Context<Callbacks | null>} */ (
    createContext(null)
  );

export function LockTrackerProvider(
  /** @type {{ children: React.ReactNode }} */ { children },
) {
  const requestCompletionListeners = useRef(
    /** @type {{ [resource: string]: import('./LockApi').RequestCompletionListener[] }} */ ({}),
  );
  const [resourceMap, setResourceMap] = useState(
    /** @type {ResourceMap} */ ({}),
  );
  const resources = useMemo(
    () => Array.from(new Set(Object.values(resourceMap))),
    [resourceMap],
  );
  const listenRequestCompletion = useCallback(
    (
      /** @type {string} */ resource,
      /** @type {import('./LockApi').RequestCompletionListener} */ listener,
    ) => {
      const listeners = requestCompletionListeners.current[resource] || [];
      requestCompletionListeners.current[resource] = listeners;
      listeners.push(listener);
      return () => {
        const listeners = requestCompletionListeners.current[resource];
        if (listeners) {
          requestCompletionListeners.current[resource] = listeners.filter(
            (x) => x !== listener,
          );
        }
      };
    },
    [],
  );

  const emitRequestCompletion = useCallback(
    (/** @type {string} */ resource, /** @type {any} */ data) => {
      const listeners = requestCompletionListeners.current[resource];
      if (!listeners) return;
      listeners.forEach((listener) => {
        listener(data);
      });
    },
    [],
  );

  // Cleanup listeners when resources change
  useEffect(() => {
    const listeners = requestCompletionListeners.current;
    Object.keys(listeners).forEach((key) => {
      if (!resources.includes(key)) delete listeners[key];
    });
  }, [resources]);

  const callbacks = useMemo(
    () => ({ listenRequestCompletion, emitRequestCompletion, setResourceMap }),
    [listenRequestCompletion, emitRequestCompletion],
  );

  return (
    <ResourcesContext.Provider value={resources}>
      <CallbacksContext.Provider value={callbacks}>
        {children}
      </CallbacksContext.Provider>
    </ResourcesContext.Provider>
  );
}

export function useLockedResources() {
  const resources = useContext(ResourcesContext);
  if (!resources) {
    throw new Error(`LockTrackerProvider is missing`);
  }
  return resources;
}

function useCallbacks() {
  const callbacks = useContext(CallbacksContext);
  if (!callbacks) {
    throw new Error(`LockTrackerProvider is missing`);
  }
  return callbacks;
}

function useSetLockResourceMap() {
  const callbacks = useCallbacks();
  return callbacks.setResourceMap;
}

/**
 * Get listenRequestCompletion callback.
 */
export function useListenRequestCompletion() {
  const { listenRequestCompletion } = useCallbacks();
  return listenRequestCompletion;
}

/**
 * Get emitRequestCompletion callback.
 */
export function useEmitRequestCompletion() {
  const { emitRequestCompletion } = useCallbacks();
  return emitRequestCompletion;
}

/**
 * Track a lock resource.
 * @param {string} resource
 */
export function useTrackLockResource(resource) {
  const [trackerId] = useState(() =>
    String(Math.random().toString(36).slice(2, 9)),
  );
  const setResourceMap = useSetLockResourceMap();
  useEffect(() => {
    setResourceMap((map) => ({ ...map, [trackerId]: resource }));
    return () => {
      setResourceMap((map) => {
        const cloned = { ...map };
        delete cloned[trackerId];
        return cloned;
      });
    };
  }, [resource, trackerId, setResourceMap]);
}

/**
 * Track a lock resource.
 * @param {object} props
 * @param {string} props.id
 */
export function TrackLockResource({ id }) {
  useTrackLockResource(id);
  return null;
}
