import { Button as AriakitButton } from "ariakit/button";
import { Command } from "ariakit/command";
import clsx from "clsx";
import {
  ComponentProps,
  RefObject,
  createContext,
  memo,
  useContext,
  useRef,
} from "react";
import { Badge } from "swash/Badge";
import { IoChevronDown } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { useHasOverflow } from "swash/utils/useHasOverflow";

export const List = (props: ComponentProps<"div">) => {
  return <div className="" {...props} />;
};

type ListGroupHeaderProps = {
  label: string;
  open: boolean;
  count: number;
  onClick: ComponentProps<"div">["onClick"];
  isSubGroup?: boolean;
};

export const ListGroupHeader = memo((props: ListGroupHeaderProps) => {
  return (
    <AriakitButton
      as="div"
      className={clsx(
        "flex grow select-none items-center gap-2 rounded px-2 py-1.5 text-grey-on transition",
        props.count && "hover:bg-grey-bg-hover-transparent",
        props.isSubGroup ? "mt-3" : "mt-6",
      )}
      disabled={!props.count}
      onClick={props.onClick}
    >
      <div className="shrink-0">
        <IoChevronDown
          className={clsx(
            "transition",
            (!props.open || !props.count) && "-rotate-90",
          )}
        />
      </div>
      <div
        className={clsx(
          "shrink-0 font-accent uppercase",
          props.isSubGroup ? "text-sm font-semibold" : "text-md font-bold",
        )}
      >
        {props.label}
      </div>
      <Badge className="shrink-0" color={props.count ? "primary" : "secondary"}>
        {props.count}
      </Badge>
      {!props.isSubGroup && (
        <div className="flex-1 border-t border-t-grey-border-light" />
      )}
    </AriakitButton>
  );
});

export type ListHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

export const ListHeader = (props: ListHeaderProps) => {
  return (
    <div
      className={clsx(
        props.className,
        "flex grow gap-4 font-accent text-2xs leading-4 text-grey-on",
      )}
    >
      {props.children}
    </div>
  );
};

export type ListHeaderCellProps = {
  children: React.ReactNode;
  style: ComponentProps<"div">["style"];
};

export const ListHeaderCell = (props: ListHeaderCellProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasOverflow = useHasOverflow({ ref, dimension: "x" });
  const element = (
    <div
      ref={ref}
      className="select-none overflow-hidden text-ellipsis whitespace-nowrap px-1 py-1"
      style={props.style}
    >
      {props.children}
    </div>
  );
  // When the content is too long, we display a tooltip.
  if (hasOverflow) {
    return <Tooltip tooltip={props.children as string}>{element}</Tooltip>;
  }
  return element;
};

type ListRowProps = {
  children: React.ReactNode;
  first: boolean;
  last: boolean;
  active?: boolean;
  hidden?: boolean;
  onActive?: () => void;
  className?: string;
  viewed?: boolean;
};

type ListRowContextType = RefObject<HTMLDivElement> | null;

const ListRowContext = createContext<ListRowContextType>(null);

export const ListRow = (props: ListRowProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ListRowContext.Provider value={ref}>
      <Command
        ref={ref}
        as="div"
        focusable
        hidden={props.hidden}
        role="row"
        className={clsx(
          props.className,
          "group/list-row grow select-none gap-4 border-x border-b border-grey-border-light",
          props.hidden ? "hidden" : "flex",
          !props.viewed &&
            "animate-pulse !bg-primary-bg-light hover:!bg-primary-bg-hover-light",
          props.active
            ? "bg-primary-bg-light"
            : clsx("bg-white", props.onActive && "hover:bg-grey-bg-light"),
          props.first && "rounded-t border-t",
          props.last && "rounded-b",
        )}
        onClick={() => {
          if (props.onActive) props.onActive();
        }}
      >
        {props.children}
      </Command>
    </ListRowContext.Provider>
  );
};

export const useListRowRef = () => {
  return useContext(ListRowContext);
};

export type ListCellProps = {
  children: React.ReactNode;
  className?: string;
  style: ComponentProps<"div">["style"];
};

export const ListCell = ({ className, ...props }: ListCellProps) => {
  return (
    <div
      role="cell"
      className={clsx("overflow-hidden px-1 py-2", className)}
      {...props}
    />
  );
};
