import { useField } from "react-final-form";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import {
  EditorPanel,
  EditorPanelBody,
  EditorPanelHeader,
  EditorPanelHeaderTitle,
} from "@/containers/common/EditorLayout";
import { Editor } from "@/containers/common/codemirror/Editor";
import { composeValidators, mustBeFilled } from "@/services/forms/validators";

function useEditorField(
  name,
  { required = false, validate, id, orientation = "vertical", ...options } = {},
) {
  const validators = [];
  if (validate) {
    validators.push(validate);
  }
  if (required) {
    validators.push(mustBeFilled);
  }

  const validateFunction = validators.length
    ? composeValidators(...validators)
    : undefined;

  const field = useField(name, {
    validate: validateFunction,
    ...options,
  });

  return useFieldState({
    field,
    id,
    orientation,
    required,
  });
}

function EditorMode({ state, extensions, readOnly }) {
  return (
    <Editor
      extensions={extensions}
      smartIndent={false}
      readOnly={readOnly}
      {...state.field.input}
    />
  );
}

export function EditorField({
  name,
  extensions,
  label,
  hint,
  disabled,
  className,
  ...options
}) {
  const field = useEditorField(name, options);

  return (
    <>
      <FieldGroup {...field}>
        <FieldLabel {...field}>{label}</FieldLabel>
        <FieldError {...field} />
        {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
        <EditorPanel>
          <EditorPanelHeader>
            <EditorPanelHeaderTitle>{label}</EditorPanelHeaderTitle>
          </EditorPanelHeader>
          <EditorPanelBody>
            <div className="h-52">
              <EditorMode
                extensions={extensions}
                readOnly={disabled}
                {...field}
              />
            </div>
          </EditorPanelBody>
        </EditorPanel>
      </FieldGroup>
    </>
  );
}
