import { gql } from "@apollo/client";

import { useHasPermission } from "@/containers/User";
import {
  ArticleExposuresEdit,
  ArticleExposuresEditProps,
} from "@/containers/article/ArticleExposureEdit";
import type { CellType } from "@/containers/list/ListState";

export type ExposuresCellArticle = ArticleExposuresEditProps["article"];

export const exposuresCell: CellType<ExposuresCellArticle> = {
  key: "exposures",
  title: "Expositions",
  width: 80,
  cellPadding: "0.25rem 0",
  render: ({ node }) => {
    return <ArticleExposuresEdit article={node} />;
  },
  fragment: gql`
    fragment exposuresCell_article on Article {
      ...ArticleExposuresEdit_article
    }
    ${ArticleExposuresEdit.fragments.article}
  `,
  useIsVisible: () => {
    const canView = useHasPermission(
      ["exposures:view", "exposures:edit", "exposures:full"],
      { method: "some" },
    );
    return canView;
  },
};

export type InsightsExposuresCellArticle = { article: ExposuresCellArticle };

export const insightsExposuresCell: CellType<InsightsExposuresCellArticle> = {
  ...exposuresCell,
  width: 106,
  cellPadding: "0.25rem 0.75rem",
  render: ({ node }) => {
    return <ArticleExposuresEdit article={node.article} />;
  },
};
