import { gql } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import * as React from "react";

import { useAmplitude } from "./Amplitude";
import { useSafeQuery } from "./Apollo";
import { UserAvatar } from "./user/UserAvatar";

/**
 * @typedef {'user' | 'manager' | 'developer' | 'admin' } UserLevel
 */
export const USER_LEVELS = [
  { name: "user", label: "Utilisateur" },
  { name: "manager", label: "Manager" },
  { name: "developer", label: "Développeur" },
  { name: "admin", label: "Administrateur" },
];

/**
 * @typedef {object} User
 * @property {number} id
 * @property {string} globalId
 * @property {string[]} permissions
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {UserLevel} level
 * @property {{ id: number, name: string } | null} currentSection
 * @property {{ id: number, name: string }[]} sections
 * @property {{ id: number, name: string } | null} currentRole
 * @property {{ id: number, name: string }[]} roles
 * @property {{ feature: { name: string } }[]} experimentalFeatures
 * @property {{ openLinkInNewTab: boolean, articleTypographySerif: boolean, articleDeviceDisplay: "desktop" | "mobile" }} settings
 */

/**
 * @typedef {'every' | 'some'} CheckMethod
 */

/** @type {React.Context<null | User>} */
const UserContext = React.createContext(/** @type {null | User} */ (null));

const GET_USER = gql`
  query MeQuery {
    me {
      id
      createdAt
      globalId
      permissions
      firstName
      firstNameInitials
      lastName
      fullName
      email
      level
      signature {
        id
        longName
      }
      isSuperAdmin
      currentRole {
        id
        name
      }
      roles {
        id
        name
      }
      sections {
        id
        name
      }
      currentSection {
        id
        name
      }
      experimentalFeatures {
        feature {
          name
        }
      }
      notificationPermissionRequestedAt
      slackId
      settings {
        openLinkInNewTab
        articleMediaDisplayRead
        articleMediaDisplayWrite
        articleTypographySerif
        articleDeviceDisplay
      }
      preferences
      selectableColumns
      ...UserAvatar_user
    }
  }

  ${UserAvatar.fragments.user}
`;

/**
 * @typedef {object} UserInitializerProps
 * @property {React.ReactNode} children
 */

/**
 * @type {React.FC<UserInitializerProps>}
 */
export function UserInitializer(
  /** @type {UserInitializerProps} */ { children },
) {
  const { initUser: initAmplitudeUser } = useAmplitude();
  const { loading, data } = useSafeQuery(GET_USER);
  const me = data ? data.me : null;
  React.useEffect(() => {
    if (me) {
      Sentry.getCurrentScope().setUser({
        id: me.globalId,
        permissions: me.permissions,
        firstName: me.firstName,
        lastName: me.lastName,
        email: me.email,
      });
      if (initAmplitudeUser) initAmplitudeUser(me);
    }
  }, [me, initAmplitudeUser]);
  if (loading) return null;
  if (!me) throw new Error("No user loaded");
  return <UserContext.Provider value={me}>{children}</UserContext.Provider>;
}

export function useUser() {
  const user = React.useContext(UserContext);
  if (!user) throw new Error("No user loaded");
  return user;
}

export function useUserPermissions() {
  const user = useUser();
  return user ? user.permissions : [];
}

/**
 * Check if the user has a permission.
 * @param {User} user
 * @returns {(name: string) => boolean}
 */
const checkUserHasPermission = (user) => (name) =>
  user.permissions.includes(name);

/**
 * Check if the user has a permission.
 * @param {User} user
 * @param {string[]} permissions
 * @param {Object} options
 * @param {CheckMethod} [options.method]
 * @param {Function} [options.checkFunction]
 * @returns {boolean}
 */
export const checkUserHasPermissions = (
  user,
  permissions,
  { method = "every", checkFunction = checkUserHasPermission } = {},
) => {
  if (permissions.length === 0) return true;

  switch (method) {
    case "every":
      return permissions.every(checkFunction(user));
    case "some":
      return permissions.some(checkFunction(user));
    default:
      return false;
  }
};

/**
 * Check if the user has a permission
 * @param {string | string[]} permission
 * @param {object} options
 * @param {CheckMethod} [options.method]
 * @returns {boolean}
 */
export function useHasPermission(permission, { method = "every" } = {}) {
  const user = useUser();
  const permissionsArray =
    typeof permission === "string" ? [permission] : permission;
  return checkUserHasPermissions(user, permissionsArray, {
    method,
  });
}

/**
 * @typedef {object} HasPermissionProps
 * @property {React.ReactNode} children
 * @property {string | string[]} permission
 * @property {CheckMethod} [method]
 */

export function HasPermission(
  /** @type {HasPermissionProps} */ { permission, method = "every", children },
) {
  const hasPermission = useHasPermission(permission, { method });
  return /** @type {import("react").ReactElement} */ (
    hasPermission ? children : null
  );
}

/**
 * @param {User} user
 * @returns {(name: string) => boolean}
 */
const checkUserHasExperimentalFeature = (user) => (name) =>
  user.experimentalFeatures.some(
    (experimentalFeature) => experimentalFeature.feature.name === name,
  );

/**
 * Check if the user has an experimental feature activated.
 * @param {string | string[]} [feature]
 * @param {object} options
 * @param {'every' | 'some'} [options.method]
 */
export function useHasExperimentalFeature(feature, { method = "every" } = {}) {
  const user = useUser();
  if (feature === undefined) return true;
  const featureArray = typeof feature === "string" ? [feature] : feature;
  switch (method) {
    case "every":
      return featureArray.every(checkUserHasExperimentalFeature(user));
    case "some":
      return featureArray.some(checkUserHasExperimentalFeature(user));
    default:
      return false;
  }
}

/**
 * @typedef {object} HasExperimentalFeatureProps
 * @property {React.ReactNode} children
 * @property {string | string[]} feature
 * @property {CheckMethod} [method]
 */

/**
 * @type {React.FC<HasExperimentalFeatureProps>}
 */
export function HasExperimentalFeature(
  /** @type {HasExperimentalFeatureProps} */ { feature, method, children },
) {
  const hasFeature = useHasExperimentalFeature(feature, { method });
  return hasFeature ? children : null;
}

/**
 * @param {string} levelName
 */
const getLevelIndex = (levelName) =>
  USER_LEVELS.findIndex(({ name }) => name === levelName);

/**
 * Check if the user has a level access
 * @param {User} user
 * @param {string} level
 * @returns {boolean}
 */
export function checkHasLevelAccess(user, level) {
  return getLevelIndex(user.level) >= getLevelIndex(level);
}

/**
 * Check if the user has a level access
 * @param {UserLevel | null} level
 * @returns {boolean}
 */
export function useHasLevelAccess(level) {
  const user = useUser();
  if (level === null) return true;
  return checkHasLevelAccess(user, level);
}

/**
 * @typedef {object} HasLevelAccessProps
 * @property {React.ReactNode} children
 * @property {UserLevel | null} level
 */

/**
 * @type {React.FC<HasPermissionProps>}
 */
export function HasLevelAccess(
  /** @type {HasLevelAccessProps} */ { level, children },
) {
  const hasLevelAccess = useHasLevelAccess(level);
  return hasLevelAccess ? children : null;
}

export function HasPermissionOrLevelAccess(
  /** @type {HasPermissionProps & HasLevelAccessProps} */ {
    permission,
    method = "every",
    level,
    children,
  },
) {
  const hasPermission = useHasPermission(permission, { method });
  const hasLevelAccess = useHasLevelAccess(level);

  return hasLevelAccess || hasPermission ? children : null;
}
