import arrayMutators from "final-form-arrays";
import { useMemo } from "react";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogState } from "swash/Dialog";

import { Header, Main, Section } from "@/components/Layout";
import { Form } from "@/components/forms/Form";
import { PlusCircle } from "@/components/icons";
import { HeaderPrimary, PageLink } from "@/components/teleporters/Header";
import {
  HeaderToolbar,
  HeaderToolbarItem,
} from "@/components/teleporters/HeaderToolbar";
import { useSafeMutation } from "@/containers/Apollo";
import { blockIfNotAllowed } from "@/containers/BlockIfNotAllowed";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { useUser } from "@/containers/User";
import * as customMutators from "@/services/forms/mutators";

const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

function CreateWithDialogButton({ descriptor, children }) {
  const dialog = useDialogState();
  const user = useUser();
  const config = useRemoteConfig();
  const [createNode] = useSafeMutation(
    descriptor.operations.CreateNodeMutation,
  );

  const initialValues = useMemo(
    () => descriptor.formatValues(null, { user, config }),
    /*
      Because Ariakit manages the opening and closing of the modal,
      we react on the `open` field of the modal state
      to initialize the initial values. Without this, the modal
      keeps the title value from the previous opening of the dialog.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, config, descriptor, dialog.open],
  );
  const navigate = useNavigate();

  async function handleSubmit(values) {
    const result = await createNode({
      variables: { input: descriptor.parseValues({ ...values, code: "" }) },
    });
    navigate(
      `${descriptor.baseUrl}/${descriptor.slug}/${result.data.createNode.id}`,
    );
  }

  return (
    <Form
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{ ...customMutators, ...arrayMutators }}
    >
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <Button scale="sm" {...disclosureProps}>
            {children}
          </Button>
        )}
      </DialogDisclosure>
      <descriptor.components.CreateDialog state={dialog} />
    </Form>
  );
}

function CreateButton({ descriptor, children }) {
  return !descriptor.components.CreateDialog ? (
    <Button scale="sm" asChild>
      <Link to={`${descriptor.baseUrl}/${descriptor.slug}/create`}>
        {children}
      </Link>
    </Button>
  ) : (
    <CreateWithDialogButton descriptor={descriptor}>
      {children}
    </CreateWithDialogButton>
  );
}

/**
 * Create CRUD admin.
 * @param {object} ctx
 * @param {import('./index').CRUDDescriptor} ctx.descriptor
 */
export function createCRUDRoot({ descriptor }) {
  function CRUDRoot() {
    return descriptor.listOnly ? (
      <div className="bg-grey-bg-light pb-1">
        <Routes>
          <Route index element={<descriptor.components.List />} />
        </Routes>
      </div>
    ) : (
      <Main>
        <Header>
          <HeaderPrimary />
          <HeaderToolbar />
          {descriptor.components.Create && (
            <HeaderToolbarItem order={3}>
              <CreateButton descriptor={descriptor}>
                <PlusCircle />
                Créer {descriptor.term.article} {descriptor.term.singular}
              </CreateButton>
            </HeaderToolbarItem>
          )}
        </Header>

        <PageLink to={`${descriptor.baseUrl}/${descriptor.slug}`} end>
          {descriptor.title}
        </PageLink>

        <Routes>
          {descriptor.components.List && (
            <Route
              index
              ErrorBoundary={BubbleError}
              element={<descriptor.components.List />}
            />
          )}
          {descriptor.components.Create && (
            <Route
              path="create"
              ErrorBoundary={BubbleError}
              element={
                <Section
                  maxWidth={descriptor.layout === "large" ? "none" : undefined}
                >
                  <descriptor.components.Create />
                </Section>
              }
            />
          )}

          {descriptor.components.Edit && (
            <Route
              path=":id/*"
              ErrorBoundary={BubbleError}
              element={
                <Section
                  maxWidth={descriptor.layout === "large" ? "none" : undefined}
                >
                  <descriptor.components.Edit />
                </Section>
              }
            />
          )}
        </Routes>
      </Main>
    );
  }
  return {
    Root: blockIfNotAllowed({
      level: descriptor.levelAccess,
      permissions: descriptor.permissions,
    })(CRUDRoot),
  };
}
