import { forwardRef, useMemo } from "react";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { Dialog, DialogDisclosure, useDialogState } from "swash/Dialog";
import { IoInformationCircleOutline, IoOptions } from "swash/Icon";
import { Link } from "swash/Link";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";

import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useAmplitude } from "@/containers/Amplitude";

import { operators } from "./operators";
import { formatSearchText, parseSearchText } from "./utils";

const Expression = ({ label, name }) => {
  const field = useTextInputField(name);
  return (
    <>
      <FieldGroup {...field}>
        <FieldLabel {...field}>{label}</FieldLabel>
        <TextInputField {...field} scale="lg" />
      </FieldGroup>
    </>
  );
};
const AdvancedSearchForm = ({ dialog, search, onSubmit }) => {
  const initialValues = useMemo(() => parseSearchText(search), [search]);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <PanelHeader title="Recherche avancée" onClose={dialog.hide} />
      <PanelBody>
        <div className="flex flex-col gap-4">
          {operators.map((operator, key) => (
            <Expression {...operator} key={key} />
          ))}
        </div>
      </PanelBody>
      <PanelFooter>
        <div className="flex flex-grow">
          <Link
            icon={<IoInformationCircleOutline />}
            href="https://sirius-support.zendesk.com/hc/fr/articles/360017915319-Comment-rechercher-un-article-"
            target="_blank"
            className="ml-2"
          >
            Découvrez comment faire une recherche enrichie sur Sirius
          </Link>
        </div>
        <Button
          type="button"
          variant="secondary"
          appearance="text"
          onClick={() => dialog.hide()}
        >
          Annuler
        </Button>
        <FormSubmit>Rechercher</FormSubmit>
      </PanelFooter>
    </Form>
  );
};

const AdvancedSearchButton = forwardRef((props, ref) => (
  <Button
    ref={ref}
    variant="secondary"
    appearance="text"
    iconOnly
    scale="xs"
    {...props}
  >
    <IoOptions />
  </Button>
));

export const AdvancedSearch = () => {
  const dialog = useDialogState();
  const searchForm = useForm();
  const { logEvent } = useAmplitude();
  const submit = (values) => {
    if (Object.keys(values).length !== 0) {
      searchForm.mutators.setSearch(formatSearchText(values));
      logEvent("advancedSearch:validate");
    }
    dialog.hide();
  };

  return (
    <>
      <DialogDisclosure
        state={dialog}
        onClick={() => {
          logEvent("advancedSearch:open");
        }}
        as={AdvancedSearchButton}
        aria-label="Recherche avancée"
      />
      <Dialog
        state={dialog}
        aria-label="Recherche avancée"
        style={{ width: 800 }}
      >
        {dialog.open && (
          <AdvancedSearchForm
            dialog={dialog}
            search={searchForm.getState().values.search}
            onSubmit={submit}
          />
        )}
      </Dialog>
    </>
  );
};
