import clsx from "clsx";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  Payload,
} from "recharts/types/component/DefaultTooltipContent";
import { AxisDomain } from "recharts/types/util/types";
import { Skeleton } from "swash/Skeleton";

import { valueFormatter } from "@/components/charts/utils";

function CustomDot(dot: any) {
  const { cx, cy, fill } = dot;
  return (
    <svg
      fill="none"
      x={cx - 6}
      y={cy - 5}
      height="11"
      viewBox="0 0 12 11"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#f9fafb"
        height="8"
        rx="4"
        stroke={fill}
        strokeWidth="3"
        transform="matrix(-1 0 0 1 11.75 3)"
        width="8"
        x="1.5"
        y="-1.5"
      />
    </svg>
  );
}

type CustomLegendProps<L extends string> = {
  labels: Label<L>[];
  totals: Totals<L>;
  onClick: (key: L) => void;
  onMouseOver: (key: L) => void;
  onMouseOut: (key: L) => void;
  state: State<L>;
  areaFocused: boolean;
  interval: Interval;
  loading: boolean;
  config: Config<L>;
};

const CustomLegend = <L extends string>({
  labels,
  totals,
  onClick,
  onMouseOver,
  onMouseOut,
  state,
  areaFocused,
  interval,
  loading,
  config,
}: CustomLegendProps<L>) => {
  const { displayTotal = true } = config.group;
  return (
    <div className="mt-2 w-full">
      {[...labels].reverse().map(({ key }) => {
        const label = labels.find((label) => label.key === key) as Label<L>;
        const selected = state[key]?.selected ?? false;
        const value: { value: number; unit: Unit } =
          totals[`${key}Rate` as L] != undefined
            ? { value: totals[`${key}Rate` as L], unit: "percent" }
            : { value: totals[key], unit: "number" };
        return (
          <button
            key={key}
            className={clsx(
              "group flex w-full flex-col items-center justify-between py-1 font-accent text-base text-grey-on",
              areaFocused && selected && "opacity-100",
              areaFocused &&
                !selected &&
                "pointer-events-none select-none opacity-20",
            )}
            onClick={() => onClick(key)}
            onMouseOver={() => onMouseOver(key)}
            onMouseOut={() => onMouseOut(key)}
            onFocus={() => onMouseOver(key)}
            onBlur={() => onMouseOut(key)}
            disabled={loading || interval === "SINCE_PUBLICATION"}
          >
            <div
              className={clsx(
                "flex w-full items-center justify-between rounded px-2 py-1",
                interval !== "SINCE_PUBLICATION" &&
                  "group-hover:bg-secondary-bg-light",
                areaFocused &&
                  selected &&
                  "bg-primary-bg-light group-hover:!bg-primary-bg-hover-light",
              )}
            >
              <div className="flex items-center">
                <div
                  className={clsx(
                    "mr-2 h-4 w-4 rounded-sm border",
                    label.tailwind,
                  )}
                />
                {label.legend}
              </div>
              <div className="font-semibold text-info-on">
                {loading ? "—" : valueFormatter(value)}
              </div>
            </div>
            {label?.hint && (
              <div className="mx-2 text-justify text-sm leading-4">
                {label.hint}
              </div>
            )}
          </button>
        );
      })}
      {displayTotal && (
        <div className="flex items-center justify-between py-1 font-accent text-base text-grey-on">
          <div className="flex w-full items-center justify-between rounded bg-secondary-bg-light px-2 py-1">
            <div>Total sur la période</div>
            <div className="font-semibold text-info-on">
              {loading ? "—" : valueFormatter({ value: totals.total })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CustomXAxis = ({ label }: { label: string }) => {
  return (
    <div className="z-popover flex h-auto items-center justify-center">
      <div className="chart-axis-label text-xs text-white">{label}</div>
      <div className="absolute text-xs text-grey-on">{label}</div>
    </div>
  );
};

const CustomYAxis = ({ label }: { label: string }) => {
  return (
    <div className="z-popover flex h-auto items-center justify-center">
      <div className="chart-axis-label text-xs text-white">{label}</div>
      <div className="absolute text-xs text-primary-on">{label}</div>
    </div>
  );
};

type TooltipPayloadProps<L extends string> = {
  interval: Interval;
  labels: Label<L>[];
  payload?: (Payload<number, NameType> & { fill: string })[];
} & TooltipProps<number, NameType>;

type TooltipData = {
  total: number;
  date: string;
};

const CustomTooltip = <L extends string>(tooltip: TooltipPayloadProps<L>) => {
  const { active, payload, interval } = tooltip;

  if (!active || !payload?.length) return null;

  const sum = payload.reduce(
    (acc: TooltipData, day) => {
      if (!acc["date"]) {
        acc["date"] =
          interval === "LAST_24_HOURS"
            ? moment(day.payload.date).format("HH:mm")
            : moment(day.payload.date).format("DD/MM/YY");
      }
      acc["total"] += day.value ?? 0;
      return acc;
    },
    { total: 0 } as TooltipData,
  );

  return (
    <div className="fon-accent rounded border-grey-border-light bg-white p-2 text-sm shadow">
      <div className="flex items-center justify-between gap-x-2">
        <div>{sum.date}</div>
        <div>{valueFormatter({ value: sum.total })}</div>
      </div>
      {[...payload].reverse().map((item) => {
        return (
          <div
            key={item.dataKey}
            className="flex items-center justify-between gap-x-2"
          >
            <div className="text-grey-on">{item.name}</div>
            <div style={{ color: item.fill }}>
              {valueFormatter({ value: item.value })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

type PeriodChartProps<L extends string> = {
  data: DataItem<L>[];
  interval: Interval;
  state: State<L>;
  areas: Label<L>[];
  config: Config<L>;
  loading: boolean;
  domain: AxisDomain;
};

const PeriodChart = <L extends string>({
  data,
  interval,
  state,
  areas,
  config,
  loading,
  domain,
}: PeriodChartProps<L>) => {
  const { height, yLimit, animationDuration, labels } = config;

  if (loading || !data)
    return (
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={[]}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          style={{ backgroundColor: "#F9FAFB" }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontalPoints={[height / 2]}
            vertical={false}
          />
          <YAxis hide padding={{ top: 5 }} domain={yLimit} />
          <XAxis hide padding={{ left: 6, right: 6 }} />
        </AreaChart>
      </ResponsiveContainer>
    );

  switch (interval) {
    case "SINCE_PUBLICATION": {
      return (
        <ResponsiveContainer width="100%" height={height}>
          <BarChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            style={{ backgroundColor: "#F9FAFB" }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              horizontalPoints={[height / 2]}
              vertical={false}
            />
            <YAxis hide padding={{ top: 5 }} domain={yLimit} />
            <XAxis hide padding={{ left: 6, right: 6 }} />
            {!loading &&
              [...areas]
                .reverse()
                .map((label, index) => (
                  <Bar
                    key={index}
                    dataKey={label.key}
                    fill={state[label.key].color}
                    fillOpacity={state[label.key].fillOpacity}
                    stroke={state[label.key].color}
                    strokeWidth={2}
                    opacity={state[label.key].opacity}
                    animationDuration={animationDuration}
                    activeBar={false}
                  />
                ))}
          </BarChart>
        </ResponsiveContainer>
      );
    }
    case "LAST_24_HOURS":
    case "LAST_7_DAYS":
    case "LAST_30_DAYS": {
      return (
        <ResponsiveContainer width="100%" height={height}>
          <AreaChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            style={{ backgroundColor: "#F9FAFB" }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              horizontalPoints={[height / 2]}
              vertical={false}
            />
            <XAxis hide dataKey="date" type="number" domain={domain} />
            <YAxis hide padding={{ top: 5 }} domain={yLimit} />
            <Tooltip
              content={<CustomTooltip interval={interval} labels={labels} />}
            />
            {!loading &&
              areas.map((label) => (
                <Area
                  isAnimationActive={true}
                  key={label.key}
                  dataKey={label.key}
                  name={label.legend}
                  stackId="1"
                  stroke={state[label.key].color}
                  strokeWidth={2}
                  fill={state[label.key].color}
                  fillOpacity={state[label.key].fillOpacity}
                  opacity={state[label.key].opacity}
                  activeDot={<CustomDot />}
                  animationDuration={animationDuration}
                />
              ))}
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    default: {
      return null;
    }
  }
};

type Label<L extends string> = {
  key: L;
  color: string;
  tailwind: string;
  legend: string;
  hint?: string;
};

export type DataItem<L extends string> = Record<L, number> & { date: number };

type Data<L extends string> = {
  aggregateDataPoints: DataItem<L>[];
  totals: Totals<L>;
};

export type Interval =
  | "UNSPECIFIED"
  | "SINCE_PUBLICATION"
  | "LAST_30_DAYS"
  | "LAST_7_DAYS"
  | "LAST_24_HOURS";

export type Unit = "number" | "percent" | "duration";

type Totals<L extends string> = Record<L, number> & {
  overall?: number;
  total?: number;
};

export type Config<L extends string> = {
  yLimit: [number | string, number | string];
  height: number;
  animationDuration: number;
  labels: Label<L>[];
  group: {
    yAxisLabel: string;
    title: string;
    displayTotal?: boolean;
  };
};

type StateEntry = {
  opacity: number;
  fillOpacity: number;
  color: string;
  selected: boolean;
};

type State<L extends string> = Record<L, StateEntry>;

type ForecastInsightsChartProps<L extends string> = {
  config: Config<L>;
  data: Data<L>;
  interval: Interval;
  loading: boolean;
  article?: {
    initialFirstPublished: string;
  };
};

export const ForecastInsightsChart = <L extends string>({
  config,
  data,
  interval,
  loading,
  article,
}: ForecastInsightsChartProps<L>) => {
  const { labels } = config;

  const [state, setState] = useState<State<L>>(() => {
    const initialMap = labels.reduce(
      (acc, label) => {
        acc[label.key] = {} as StateEntry;
        return acc;
      },
      {} as Record<L, StateEntry>,
    );

    return labels.reduce((acc, label) => {
      acc[label.key] = {
        opacity: 1,
        fillOpacity: 0.2,
        color: label.color,
        selected: false,
      };
      return acc;
    }, initialMap);
  });

  const { dataKeys, areaFocused, areas } = useMemo(() => {
    const dataKeys = labels.map(({ key }) => key);
    const areaFocused = dataKeys.some((key) => state[key].selected);
    const areas = areaFocused
      ? labels.filter((label) => state[label.key].selected)
      : labels;

    return { dataKeys, areaFocused, areas };
  }, [labels, state]);

  const aggregateDataPoints = useMemo(
    () => data?.aggregateDataPoints ?? [],
    [data],
  );
  const totals = useMemo(() => data?.totals ?? {}, [data]);

  const handleMouseOver = useCallback(
    (dataKey: L) => {
      if (interval === "SINCE_PUBLICATION" || state[dataKey].selected) return;

      setState(({ ...state }) => {
        for (const key in state) {
          state[key] =
            key === dataKey
              ? { ...state[key], fillOpacity: 0.4 }
              : {
                  ...state[key],
                  fillOpacity: 0,
                  opacity: 0.6,
                  color: "#E3E7EC",
                };
        }
        return state;
      });
    },
    [state, interval],
  );

  const handleMouseOut = useCallback(
    (dataKey: L) => {
      if (interval === "SINCE_PUBLICATION" || state[dataKey].selected) return;

      setState(({ ...state }) => {
        for (const key in state) {
          const label = labels.find((label) => label.key === key);
          state[key] = {
            ...state[key],
            fillOpacity: 0.2,
            opacity: 1,
            color: label?.color,
          };
        }
        return state;
      });
    },
    [state, labels, interval],
  );

  const selectArea = useCallback(
    (dataKey: L) => {
      if (interval === "SINCE_PUBLICATION" || !state) return;
      if (!state[dataKey].selected) {
        setState(({ ...state }) => {
          for (const key in state) {
            state[key] = {
              ...state[key],
              selected: key === dataKey,
            };
          }
          return state;
        });
      } else {
        setState(({ ...state }) => {
          for (const key in state) {
            state[key] = {
              ...state[key],
              selected: false,
            };
          }
          return state;
        });
      }
    },
    [state, interval],
  );

  const yAxisLabel = useMemo(() => {
    if (!aggregateDataPoints.length) return;
    if (areaFocused) {
      const selectedKey = dataKeys.find((dataKey) => state[dataKey].selected);
      const sKey = selectedKey as L;
      return Math.max(...aggregateDataPoints.map((point) => point[sKey]));
    }
    return Math.max(
      ...aggregateDataPoints.map((point) =>
        Object.entries(point).reduce((acc, [key, value]) => {
          if (key === "date" || key === "__typename") return acc;
          return acc + value;
        }, 0),
      ),
    );
  }, [areaFocused, dataKeys, aggregateDataPoints, state]);

  const domain: AxisDomain = useMemo(() => {
    switch (interval) {
      case "LAST_24_HOURS":
        return [
          moment().subtract(1, "day").startOf("hour").valueOf(),
          moment().valueOf(),
        ];
      case "LAST_7_DAYS":
        return [
          moment().subtract(7, "days").startOf("day").valueOf(),
          moment().startOf("day").valueOf(),
        ];
      case "LAST_30_DAYS":
        return [
          moment().subtract(30, "days").startOf("day").valueOf(),
          moment().startOf("day").valueOf(),
        ];
      case "SINCE_PUBLICATION": {
        if (article?.initialFirstPublished) {
          return [
            moment(article.initialFirstPublished).valueOf(),
            moment().startOf("day").valueOf(),
          ];
        }
        return [0, "dataMax"];
      }
      default:
        return [0, "dataMax"];
    }
  }, [interval]);

  return (
    <>
      <div className="relative flex w-full justify-start">
        <CustomYAxis
          label={`${yAxisLabel || "–"} ${config.group.yAxisLabel}`}
        />
      </div>
      <div className="relative -mt-2 flex w-full flex-col items-center justify-center overflow-hidden rounded-md border border-grey-border-light">
        {loading && (
          <Skeleton
            id="skeleton"
            className="absolute z-popover h-full w-full opacity-50"
            delay={0}
          />
        )}
        <PeriodChart
          data={aggregateDataPoints}
          state={state}
          areas={areas}
          interval={interval}
          config={config}
          loading={loading}
          domain={domain}
        />
      </div>
      <div
        className={clsx(
          "relative bottom-2 flex w-full justify-between px-1",
          interval === "SINCE_PUBLICATION" && "invisible",
        )}
      >
        <CustomXAxis
          label={
            domain[0] ? moment(domain[0] as number).format("DD/MM/YY") : "—"
          }
        />
        <CustomXAxis label="Aujourd'hui" />
      </div>
      <CustomLegend
        labels={labels}
        state={state}
        areaFocused={areaFocused}
        totals={totals}
        onClick={selectArea}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        interval={interval}
        loading={loading}
        config={config}
      />
    </>
  );
};
