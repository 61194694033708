// @ts-check
import { useLockSubscriptions } from "./LockApi";
import { LockTrackerProvider, useLockedResources } from "./LockTracker";
import { LockUpdatingProvider } from "./LockUpdatingProvider";

const LockPoller = () => {
  const resources = useLockedResources();
  useLockSubscriptions({ ids: resources });
  return null;
};

export function LockProvider(
  /** @type {{ children: React.ReactNode }} */ { children },
) {
  return (
    <LockTrackerProvider>
      <LockUpdatingProvider>
        <LockPoller />
        {children}
      </LockUpdatingProvider>
    </LockTrackerProvider>
  );
}
