/* eslint-disable no-param-reassign */
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { ImageCropPreview } from "./ImageCropPreview";

const Corner = ({ className, ...props }) => (
  <div
    className={clsx(
      className,
      "absolute z-10 block h-6 w-6 rounded-sm bg-primary-bg-strong opacity-80",
    )}
    {...props}
  />
);

const useDocumentListener = (event, listener) => {
  const listenerRef = useLiveRef(listener);
  useEffect(() => {
    const listener = (...args) => {
      listenerRef.current(...args);
    };
    document.addEventListener(event, listener);
    return () => {
      document.removeEventListener(event, listener);
    };
  }, [event, listenerRef]);
};

export const ImageCrop = ({
  imgSize,
  imgProps,
  region,
  constraint,
  onChange,
  ...props
}) => {
  const onChangeRef = useLiveRef(onChange);
  const regionRef = useLiveRef(region);
  const actionRef = useRef(null);
  const ratioRef = useRef(null);

  const constraintRatio = useMemo(() => {
    const r = constraint.x / constraint.y;
    if (Number.isNaN(r)) return null;
    return r;
  }, [constraint.x, constraint.y]);

  const resizable = Boolean(constraintRatio);

  const constraintSize = useCallback(
    ({ width, height }) => {
      width = Math.max(0, width);
      height = Math.max(0, height);
      if (!constraintRatio) return { width, height };
      const ratio = width / height;
      if (ratio > constraintRatio) {
        return { width: height * constraintRatio, height };
      }
      return { width, height: width / constraintRatio };
    },
    [constraintRatio],
  );

  const scale = useCallback(
    (value) => Math.floor(value * ratioRef.current),
    [],
  );

  const constraintRegion = useCallback(
    (region) => ({
      left: Math.round(
        Math.min(Math.max(region.left, 0), imgSize.width - region.width),
      ),
      top: Math.round(
        Math.min(Math.max(region.top, 0), imgSize.height - region.height),
      ),
      width: Math.round(Math.min(imgSize.width, Math.max(region.width, 0))),
      height: Math.round(Math.min(imgSize.height, Math.max(region.height, 0))),
    }),
    [imgSize],
  );

  const update = useCallback(
    (partialRegion) => {
      onChangeRef.current(
        constraintRegion({
          ...regionRef.current,
          ...partialRegion,
        }),
      );
    },
    [constraintRegion, onChangeRef, regionRef],
  );

  const handleRatioChange = useCallback((scale) => {
    ratioRef.current = scale;
  }, []);

  useDocumentListener("mousemove", (event) => {
    if (!resizable) return;
    const action = actionRef.current;
    if (!action) return;
    const dx = scale(action.position.x - event.pageX);
    const dy = scale(action.position.y - event.pageY);

    switch (action.type) {
      case "move": {
        update({
          left: action.region.left - dx,
          top: action.region.top - dy,
        });
        break;
      }
      case "resize-top-left": {
        const size = constraintSize({
          width: Math.min(
            action.region.width + dx,
            action.region.left + action.region.width,
          ),
          height: Math.min(
            action.region.height + dy,
            action.region.top + action.region.height,
          ),
        });
        update({
          ...size,
          top: action.region.top + action.region.height - size.height,
          left: action.region.left + action.region.width - size.width,
        });
        break;
      }
      case "resize-top-right": {
        const size = constraintSize({
          width: Math.min(
            action.region.width - dx,
            imgSize.width - action.region.left,
          ),
          height: Math.min(
            action.region.height + dy,
            action.region.top + action.region.height,
          ),
        });
        update({
          ...size,
          top: action.region.top + action.region.height - size.height,
        });
        break;
      }
      case "resize-bottom-right": {
        const size = constraintSize({
          width: Math.min(
            action.region.width - dx,
            imgSize.width - action.region.left,
          ),
          height: Math.min(
            action.region.height - dy,
            imgSize.height - action.region.top,
          ),
        });
        update(size);
        break;
      }
      case "resize-bottom-left": {
        const size = constraintSize({
          width: Math.min(
            action.region.width + dx,
            action.region.left + action.region.width,
          ),
          height: Math.min(
            action.region.height - dy,
            imgSize.height - action.region.top,
          ),
        });
        update({
          ...size,
          left: action.region.left + action.region.width - size.width,
        });
        break;
      }
      default:
    }
  });

  useDocumentListener("mouseup", (event) => {
    event.preventDefault();
    actionRef.current = null;
  });

  const handleSelectionMouseDown = useCallback(
    (event) => {
      actionRef.current = {
        type: "move",
        position: { x: event.pageX, y: event.pageY },
        region: regionRef.current,
      };
    },
    [regionRef],
  );

  const handleCornerMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      if (actionRef.current) return;
      actionRef.current = {
        type: event.currentTarget.dataset.actionType,
        position: { x: event.pageX, y: event.pageY },
        region: regionRef.current,
      };
    },
    [regionRef],
  );

  return (
    <ImageCropPreview
      imgSize={imgSize}
      imgProps={imgProps}
      region={region}
      className="h-full select-none"
      onRatioChange={handleRatioChange}
      onSelectionMouseDown={handleSelectionMouseDown}
      {...props}
    >
      {({ selection }) =>
        selection &&
        resizable && (
          <>
            <Corner
              className="-ml-2 -mt-2 cursor-nwse-resize"
              style={{
                top: Math.floor(selection.top),
                left: Math.floor(selection.left),
              }}
              data-action-type="resize-top-left"
              onMouseDown={handleCornerMouseDown}
            />
            <Corner
              className="-ml-4 -mt-2 cursor-nesw-resize"
              style={{
                top: Math.floor(selection.top),
                left: Math.floor(selection.left + selection.width),
              }}
              data-action-type="resize-top-right"
              onMouseDown={handleCornerMouseDown}
            />
            <Corner
              className="-ml-2 -mt-4 cursor-nesw-resize"
              style={{
                top: Math.floor(selection.top + selection.height),
                left: Math.floor(selection.left),
              }}
              data-action-type="resize-bottom-left"
              onMouseDown={handleCornerMouseDown}
            />
            <Corner
              className="-ml-4 -mt-4 cursor-nwse-resize"
              style={{
                top: Math.floor(selection.top + selection.height),
                left: Math.floor(selection.left + selection.width),
              }}
              data-action-type="resize-bottom-right"
              onMouseDown={handleCornerMouseDown}
            />
          </>
        )
      }
    </ImageCropPreview>
  );
};
