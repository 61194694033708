
      export default {
  "possibleTypes": {
    "AbstractWorkflowState": [
      "ExternalWorkflowState",
      "RichArticleState",
      "WorkflowState"
    ],
    "ActivityItem": [
      "AuditTrail",
      "CommentResponse",
      "CommentThread"
    ],
    "AggregatedDataPoint": [
      "AggregatedDataPointDevice",
      "AggregatedDataPointMediumReferrer"
    ],
    "AlertChannel": [
      "EmailAlertChannel",
      "FacebookAlertChannel",
      "PushAlertChannel",
      "TwitterAlertChannel"
    ],
    "ArticleBase": [
      "Article",
      "ArticleRevision"
    ],
    "ArticleEvent": [
      "ExposureArticleEvent",
      "GenericArticleEvent",
      "PublicationArticleEvent",
      "ScheduleArticleEvent",
      "WorkflowArticleEvent"
    ],
    "ArticleHistoryItem": [
      "Event",
      "ExposureArticleEvent",
      "GenericArticleEvent",
      "PublicationArticleEvent",
      "ScheduleArticleEvent",
      "WorkflowArticleEvent"
    ],
    "ArticleMediaBase": [
      "ArticleMedia",
      "ArticleMediaRevision"
    ],
    "Comment": [
      "CommentResponse",
      "CommentThread"
    ],
    "CommentMentionData": [
      "User"
    ],
    "Connection": [
      "ActivityConnection",
      "AlertConnection",
      "AppConnection",
      "AppDeliveryConnection",
      "ArticleConnection",
      "ArticleEventConnection",
      "ArticleHistoryConnection",
      "ArticleInsightsConnection",
      "ArticleMediaConnection",
      "ArticleMediaRevisionConnection",
      "ArticleRevisionConnection",
      "ArticleTeaserConnection",
      "ArticleTeaserContextConnection",
      "AspectRatioConnection",
      "AudioConnection",
      "AuditTrailConnection",
      "AuthorConnection",
      "BlacklistedContributorConnection",
      "BlockTemplateConnection",
      "BlogConnection",
      "CampaignConnection",
      "CommentResponseConnection",
      "CommentThreadConnection",
      "CustomFieldConnection",
      "CustomTypeConnection",
      "CustomTypeContentConnection",
      "EditorialFormatConnection",
      "EditorialTypeConnection",
      "EmojiConnection",
      "EventConnection",
      "EventDispatcherConnection",
      "ExportTaskConnection",
      "ExposureConnection",
      "ExternalWorkFlowStateConnection",
      "FlagConnection",
      "FtpImportItemConnection",
      "ImageAgencyConnection",
      "ImageConnection",
      "KeywordConnection",
      "LayoutConnection",
      "LayoutEditorialTypeConnection",
      "LexemeConnection",
      "LiveConnection",
      "LiveInputConnection",
      "LiveTagConnection",
      "MediaDescriptorConnection",
      "MediaPublicationConnection",
      "MessageConnection",
      "MixModelConnection",
      "NewsConnection",
      "NewsletterConnection",
      "NewsletterDeliveryConnection",
      "NotificationConnection",
      "PeriodicalConnection",
      "PeriodicalReleaseConnection",
      "PeriodicalSectionConnection",
      "PeriodicalSectionLayoutConnection",
      "PostConnection",
      "PredicateConnection",
      "PreviewConnection",
      "PricingTierConnection",
      "ProductConnection",
      "PublicationConnection",
      "RoleConnection",
      "SectionConnection",
      "SnippetConnection",
      "SourceConnection",
      "StagingAreaTypeConnection",
      "TagConnection",
      "TagTypeConnection",
      "TaskConnection",
      "UserActivityConnection",
      "UserAuthTokenConnection",
      "UserConnection",
      "UserDeviceConnection",
      "UserEventConnection",
      "WorkflowConnection",
      "WorkflowStateConnection",
      "WorkflowStateTemplateConnection"
    ],
    "CursorConnection": [
      "AggregatedRevisionCursorConnection"
    ],
    "CustomFieldConfig": [
      "ArticleCustomFieldConfig",
      "BooleanCustomFieldConfig",
      "CustomTypeContentCustomFieldConfig",
      "DateCustomFieldConfig",
      "FileCustomFieldConfig",
      "ImageCustomFieldConfig",
      "RichTextCustomFieldConfig",
      "TagCustomFieldConfig",
      "TextCustomFieldConfig"
    ],
    "EditorNode": [
      "EditorNodeArchive",
      "EditorNodeArticle",
      "EditorNodeArticleMedia",
      "EditorNodeContribution",
      "EditorNodeCustomTypesContent",
      "EditorNodeImage",
      "EditorNodeProduct",
      "EditorNodeProductSummary",
      "EditorNodeSeparator",
      "EditorNodeSnippet",
      "EditorNodeText",
      "EditorNodeTweet",
      "EditorNodeVideo"
    ],
    "EventResource": [
      "Alert",
      "Article",
      "Author",
      "Blog",
      "Campaign",
      "CampaignStagingArea",
      "Contribution",
      "CustomTypeContent",
      "EditorialType",
      "FtpImportItem",
      "GenericPublication",
      "Live",
      "Message",
      "Newsletter",
      "NewsletterPublication",
      "PeriodicalPublication",
      "PeriodicalRelease",
      "PeriodicalStagingArea",
      "Post",
      "PublicationStagingArea",
      "Snippet",
      "Source",
      "Tag",
      "TagStagingArea",
      "TagTemplateStagingArea",
      "Task"
    ],
    "GenericNews": [
      "GenAINews",
      "News"
    ],
    "GenericTag": [
      "Blog",
      "Campaign",
      "Tag"
    ],
    "GenericTask": [
      "Message",
      "Task"
    ],
    "InsightData": [
      "InsightDataDevice",
      "WebTrafficSource"
    ],
    "Integration": [
      "AccengageIntegration",
      "BatchIntegration",
      "CheetahIntegration",
      "DailymotionIntegration",
      "FacebookIntegration",
      "ForecastIntegration",
      "FtpIntegration",
      "GcpIntegration",
      "GenaiIntegration",
      "MailchimpIntegration",
      "MerciappIntegration",
      "OnesignalIntegration",
      "ProlexisIntegration",
      "ScribbleIntegration",
      "SelligentIntegration",
      "SlackIntegration",
      "TwipeIntegration",
      "TwitterIntegration",
      "WebhookIntegration",
      "YoutubeIntegration"
    ],
    "IntegrationConfig": [
      "AccengageIntegrationConfig",
      "BatchIntegrationConfig",
      "CheetahIntegrationConfig",
      "DailymotionIntegrationConfig",
      "FacebookIntegrationConfig",
      "ForecastIntegrationConfig",
      "FtpIntegrationConfig",
      "GcpIntegrationConfig",
      "GenaiIntegrationConfig",
      "MailchimpIntegrationConfig",
      "MerciappIntegrationConfig",
      "OnesignalIntegrationConfig",
      "ProlexisIntegrationConfig",
      "ScribbleIntegrationConfig",
      "SelligentIntegrationConfig",
      "SlackIntegrationConfig",
      "TwipeIntegrationConfig",
      "TwitterIntegrationConfig",
      "WebhookIntegrationConfig",
      "YoutubeIntegrationConfig"
    ],
    "LiveInput": [
      "ContributionLiveInput",
      "TweetLiveInput"
    ],
    "Media": [
      "Archive",
      "Article",
      "CustomTypeContent",
      "Image",
      "Product",
      "ProductSummary",
      "Snippet",
      "Tweet",
      "Video"
    ],
    "MetricsDevice": [
      "AggregatedDataPointDevice",
      "MetricsTotalsDevice"
    ],
    "MetricsTotals": [
      "MetricsTotalsDevice",
      "MetricsTotalsMediumReferrer"
    ],
    "NewsletterProviderConfig": [
      "CheetahNewsletterProviderConfig",
      "MailchimpNewsletterProviderConfig",
      "SelligentNewsletterProviderConfig",
      "WebhookNewsletterProviderConfig"
    ],
    "Node": [
      "AccengageIntegration",
      "Alert",
      "App",
      "AppDelivery",
      "Archive",
      "Article",
      "ArticleAlternateVersion",
      "ArticleInsights",
      "ArticleMedia",
      "ArticleMediaCollection",
      "ArticleMediaEvent",
      "ArticleMediaRevision",
      "ArticleRelatedContent",
      "ArticleRevision",
      "ArticleSchedule",
      "ArticleSpeech",
      "ArticleTeaser",
      "ArticleTeaserContext",
      "AspectRatio",
      "Audio",
      "AuditTrail",
      "Author",
      "BatchIntegration",
      "BlacklistedContributor",
      "BlockTemplate",
      "Blog",
      "Campaign",
      "CampaignStagingArea",
      "CheetahIntegration",
      "CommentResponse",
      "CommentThread",
      "Contribution",
      "ContributionLiveInput",
      "CustomField",
      "CustomType",
      "CustomTypeContent",
      "DailymotionIntegration",
      "EditorNodeArchive",
      "EditorNodeArticle",
      "EditorNodeArticleMedia",
      "EditorNodeContribution",
      "EditorNodeCustomTypesContent",
      "EditorNodeImage",
      "EditorNodeProduct",
      "EditorNodeProductSummary",
      "EditorNodeSeparator",
      "EditorNodeSnippet",
      "EditorNodeText",
      "EditorNodeTweet",
      "EditorNodeVideo",
      "EditorialFormat",
      "EditorialType",
      "Emoji",
      "Event",
      "EventDispatcher",
      "ExportTask",
      "Exposure",
      "ExposureArticleEvent",
      "ExternalWorkflowState",
      "FacebookIntegration",
      "File",
      "Flag",
      "ForecastIntegration",
      "FtpImportItem",
      "FtpIntegration",
      "GcpIntegration",
      "GenAINews",
      "GenaiIntegration",
      "GenericArticleEvent",
      "GenericPublication",
      "Image",
      "ImageAgency",
      "Keyword",
      "Layout",
      "LayoutEditorialType",
      "Lexeme",
      "Live",
      "LiveTag",
      "MailchimpIntegration",
      "MediaDescriptor",
      "MediaPublication",
      "MerciappIntegration",
      "Message",
      "MixModel",
      "ModernTagType",
      "News",
      "Newsletter",
      "NewsletterDelivery",
      "NewsletterPublication",
      "Notification",
      "OnesignalIntegration",
      "Periodical",
      "PeriodicalPublication",
      "PeriodicalRelease",
      "PeriodicalSection",
      "PeriodicalSectionLayout",
      "PeriodicalStagingArea",
      "Post",
      "Predicate",
      "PredicateConclusion",
      "Preview",
      "PricingTier",
      "Product",
      "ProductSummary",
      "ProlexisIntegration",
      "PublicationArticleEvent",
      "PublicationStagingArea",
      "PushSubscription",
      "RevisionGroup",
      "Role",
      "ScheduleArticleEvent",
      "ScribbleIntegration",
      "Section",
      "SelligentIntegration",
      "SlackIntegration",
      "Snippet",
      "Source",
      "StagingAreaType",
      "Status",
      "Tag",
      "TagStagingArea",
      "TagTemplateStagingArea",
      "Task",
      "Tweet",
      "TweetLiveInput",
      "TwipeIntegration",
      "TwitterIntegration",
      "User",
      "UserActivity",
      "UserAuthToken",
      "UserDevice",
      "Video",
      "WebhookIntegration",
      "Workflow",
      "WorkflowArticleEvent",
      "WorkflowState",
      "WorkflowStateTemplate",
      "YoutubeIntegration"
    ],
    "NotificationContent": [
      "Article",
      "ArticleSchedule",
      "ArticleSpeech",
      "Event",
      "FtpImportItem",
      "IntegrationCheck",
      "Message",
      "ModernCommentNotification",
      "News",
      "NewsletterDelivery",
      "NotificationLockContent",
      "Task"
    ],
    "NotificationLockContentResource": [
      "Article",
      "Post"
    ],
    "Publication": [
      "GenericPublication",
      "NewsletterPublication",
      "PeriodicalPublication"
    ],
    "StagingArea": [
      "CampaignStagingArea",
      "PeriodicalStagingArea",
      "PublicationStagingArea",
      "TagStagingArea",
      "TagTemplateStagingArea"
    ],
    "StagingAreaContent": [
      "ArticleStagingAreaContent",
      "TagStagingAreaContent"
    ],
    "ThirdPartyService": [
      "AccengageThirdPartyService",
      "BatchThirdPartyService",
      "CheetahThirdPartyService",
      "DailymotionThirdPartyService",
      "FacebookThirdPartyService",
      "ForecastThirdPartyService",
      "FtpThirdPartyService",
      "GcpThirdPartyService",
      "GenaiThirdPartyService",
      "MailchimpThirdPartyService",
      "MerciappThirdPartyService",
      "OnesignalThirdPartyService",
      "ProlexisThirdPartyService",
      "ScribbleThirdPartyService",
      "SelligentThirdPartyService",
      "SlackThirdPartyService",
      "TwipeThirdPartyService",
      "TwitterThirdPartyService",
      "WebhookThirdPartyService",
      "YoutubeThirdPartyService"
    ],
    "UserEventItem": [
      "Event",
      "UserActivity"
    ],
    "UserProfile": [
      "MessagesUserProfile",
      "TasksUserProfile"
    ]
  }
}
    