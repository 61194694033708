import moment from "moment";

import { Unit } from "@/components/charts/ForecastInsightsChart";
import { formatDuration } from "@/components/formatDuration";

const formatPercent = (value: number) => {
  if (value === 0) {
    return "0%";
  }
  const convertedValue = value * 100;
  if (convertedValue < 1) {
    return "<1%";
  }
  return `${convertedValue.toFixed().toLocaleString()}%`;
};

export const valueFormatter = ({
  value,
  unit = "number",
}: {
  value?: number;
  unit?: Unit;
}) => {
  if (value == undefined) return "–";
  switch (unit) {
    case "percent":
      return formatPercent(value);
    case "duration":
      return formatDuration(moment.duration(value, "seconds"));
    default:
      return value.toLocaleString();
  }
};
