import { operators, textOperators, wordOperators } from "./operators";

const getOperator = (word) => {
  for (const i in wordOperators) {
    if (wordOperators[i].parseWordCondition(word)) {
      return wordOperators[i];
    }
  }
};

export const parseSearchText = (initialText) => {
  if (!initialText?.length) return {};
  let copyText = `${initialText}`;

  const parsedSearch = textOperators.reduce((acc, operator) => {
    const { matchedText, slicedText } = operator.parseText(copyText);
    if (matchedText.length) {
      acc[operator.name] = matchedText;
      copyText = slicedText;
    }
    return acc;
  }, {});
  copyText.split(" ").forEach((word) => {
    const operator = getOperator(word);
    if (operator.parseWordCondition(word)) {
      const operatorName = operator.name;
      const matchedWord = operator.parseWordAction(word);
      if (matchedWord.length) {
        parsedSearch[operatorName] =
          (parsedSearch[operatorName] ?? "") + `${matchedWord} `;
      }
    }
  });
  return parsedSearch;
};

const formatExpressionText = (name, value) => {
  const operator = operators.find((operator) => operator.name === name);
  return operator.format(value).trim();
};
export const formatSearchText = (values) => {
  return Object.entries(values)
    .reduce((acc, [key, value]) => {
      acc += `${formatExpressionText(key, value)} `;
      return acc;
    }, "")
    .trim();
};
