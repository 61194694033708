import {
  gql,
  useApolloClient,
  useMutation,
  useSubscription,
} from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { forwardRef, memo, useMemo } from "react";
import { useForm } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";
import { Button } from "swash/Button";
import { Chip } from "swash/Chip";
import { Clickable } from "swash/Clickable";
import { IoAdd, IoTrashOutline, LinkTiltedBroken } from "swash/Icon";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";
import { FormLabel } from "swash/form/FormLabel";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { useCheckboxField } from "@/components/fields/CheckboxField";
import { ExposurePlannedDateField } from "@/components/fields/ExposurePlannedDateField";
import { FieldControl } from "@/components/fields/FieldControl";
import { useSafeQuery } from "@/containers/Apollo";
import { ArticleExposureIcon } from "@/containers/ExposureIcon";
import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { useHasPermission } from "@/containers/User";
import { HasOneField } from "@/containers/admin/CRUD";
import {
  ArticleExposuresSelectList,
  useArticleExposuresSelectState,
} from "@/containers/article/ArticleExposureSelect";
import { useTimeSlots } from "@/containers/article/filters/PlannedDateFilter";
import { formatEditionLabel } from "@/containers/common/ListPublicationsSelector";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const ExposureFragment = gql`
  fragment ExposureFieldFragment_exposure on Exposure {
    ...ArticleExposureIcon_exposure
    ...ExposureIndicator_exposure
    ...ExposurePlanneDateField_exposure
    newsletter {
      id
      timeDeadline
    }
    periodical {
      id
      periodicity {
        timeDeadline
      }
    }
  }
  ${ArticleExposureIcon.fragments.exposure}
  ${ExposureIndicator.fragments.exposure}
  ${ExposurePlannedDateField.fragments.exposure}
`;

const ExposuresQuery = gql`
  query ExposuresSectionQuery($offset: Int) {
    connection: exposures(where: { enabled: true }, offset: $offset) {
      nodes {
        ...ExposureFieldFragment_exposure
      }
      ...ConnectionFragment
    }
  }
  ${ExposureFragment}
  ${ConnectionFragment}
`;

const PublicationFragment = gql`
  fragment ExposureFieldFragment_Publication on Publication {
    id
    date
  }
`;

export const PublicationsListQuery = gql`
  query PublicationsList(
    $type: String
    $offset: Int
    $value: Int
    $date: DateTime
    $periodicalId: Int
    $newsletterId: Int
  ) {
    connection: publications(
      where: {
        type: { eq: $type }
        id: { eq: $value }
        date: { gt: $date }
        periodicalId: { eq: $periodicalId }
        newsletterId: { eq: $newsletterId }
      }
      limit: 30
      offset: $offset
    ) {
      nodes {
        id
        ...ExposureFieldFragment_Publication
      }
      ...ConnectionFragment
    }
  }
  ${PublicationFragment}
  ${ConnectionFragment}
`;

const formatArticleExposure = (articleExposures, exposureId) => {
  return (
    articleExposures.find(
      (articleExposure) =>
        Number(articleExposure.exposureId) === Number(exposureId),
    ) ?? {
      suggested: true,
      fulfilledAt: null,
      fulfilled: false,
    }
  );
};

export const ExposuresField = memo(({ article, articleExposures }) => {
  const timeSlots = useTimeSlots();
  const { data } = useSafeQuery(ExposuresQuery);
  const { fields } = useFieldArray("articleExposures");

  const hasWritePermission = useHasPermission(
    ["exposures:edit", "exposures:full"],
    {
      method: "some",
    },
  );
  const hasReadOnlyPermission = useHasPermission("exposures:view");

  if (!hasWritePermission || !hasReadOnlyPermission) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <FormLabel>Expositions de l’article</FormLabel>
      <div className="flex flex-col gap-4" aria-label="Expositions" role="list">
        {fields.map((name, index) => (
          <ArticleExposure
            key={index}
            article={article}
            articleExposure={fields.value[index]}
            articleExposures={articleExposures}
            fields={fields}
            hasWritePermission={hasWritePermission}
            index={index}
            name={name}
            timeSlots={timeSlots}
            values={data.connection.nodes}
          />
        ))}
      </div>
      {hasWritePermission ? (
        <div className="mt-4 max-w-64">
          <AddArticleExposureButton
            article={article}
            articleExposures={articleExposures}
          />
        </div>
      ) : null}
    </div>
  );
});

ExposuresField.fragments = {
  article: gql`
    fragment ExposuresField_article on Article {
      id
      ...ExposureIndicator_article
      ...ArticleExposuresSelect_article
    }

    ${ExposureIndicator.fragments.article}
    ${useArticleExposuresSelectState.fragments.article}
  `,
  articleExposures: gql`
    fragment ExposuresField_articleExposures on ArticleExposure {
      fulfilled
      exposureId
      correlatedPlannedDateToArticle
      planning {
        date
        range
      }
      publication {
        ...ExposureIndicator_publication
      }
      ...ExposureIndicator_articleExposure
    }

    ${ExposureIndicator.fragments.articleExposure}
    ${ExposureIndicator.fragments.publication}
  `,
};

const RemoveExposureMutation = gql`
  mutation ExposuresField_updateArticle(
    $articleId: Int!
    $articleExposures: [ArticleExposureInput!]
  ) {
    updateArticle(
      input: { id: $articleId, articleExposures: $articleExposures }
    ) {
      id
      articleExposures {
        nodes {
          gid
          ...ExposuresField_articleExposures
        }
      }
    }
  }
  ${ExposuresField.fragments.articleExposures}
`;

const RemoveButton = ({
  article,
  articleExposure,
  articleExposures,
  correlatedFieldName,
  ...props
}) => {
  const form = useForm();
  const [removeExposure] = useMutation(RemoveExposureMutation);
  const toaster = useToaster();

  if (articleExposure.fulfilled || articleExposure.selected) {
    return null;
  }

  return (
    <Button
      iconOnly
      appearance="text"
      variant="danger"
      onClick={() => {
        form.change(correlatedFieldName, false);
        removeExposure({
          variables: {
            articleId: article.id,
            articleExposures: articleExposures
              .filter(
                (exposure) =>
                  exposure.exposureId !== Number(articleExposure.exposureId),
              )
              .map(({ exposureId }) => ({ exposureId }))
              .filter(Boolean),
          },

          optimisticResponse: {
            __typename: "Mutation",
            updateArticle: {
              ...article,
              articleExposures: {
                ...articleExposures,
                nodes: articleExposures
                  .filter(
                    (exposure) =>
                      exposure.exposureId !== articleExposure.exposureId,
                  )
                  .filter(Boolean),
              },
            },
          },
        }).catch(() => {
          toaster.danger("La suppression de l’exposition a échoué");
        });
      }}
      {...props}
    >
      <IoTrashOutline />
    </Button>
  );
};

const AddArticleExposureButton = ({ article }) => {
  const state = useArticleExposuresSelectState({
    article,
  });
  return (
    <>
      <SelectButton asChild store={state.select}>
        <Button variant="secondary" appearance="fill-light">
          <IoAdd /> Suggérer une exposition
        </Button>
      </SelectButton>
      <SelectPopover
        store={state.select}
        modal
        aria-label="Ajouter une exposition"
      >
        <ArticleExposuresSelectList state={state} />
      </SelectPopover>
    </>
  );
};

const ExposureLabel = ({ label, isExposureOnlySuggested }) => {
  return (
    <div className={clsx(isExposureOnlySuggested && "text-secondary-on")}>
      {label} {isExposureOnlySuggested ? "(suggéré)" : null}
    </div>
  );
};

const ArticleExposurePlannedDateField = ({
  correlatedFieldName,
  name,
  exposure,
}) => {
  return (
    <ExposurePlannedDateField
      exposure={exposure}
      correlatedFieldName={correlatedFieldName}
      name={name}
      validate={(value) => {
        if (!value) return undefined;
        if (moment(value).isBefore(moment())) {
          return "Date dans le passé";
        }
        return undefined;
      }}
    />
  );
};

export const ArticleExposurePublicationField = ({
  name,
  type,
  parentId,
  timeDeadline,
}) => {
  const client = useApolloClient();
  const modelName =
    type === "newsletter" ? "NewsletterPublication" : "PeriodicalPublication";

  const dateFilter = useMemo(() => {
    if (!timeDeadline) return moment().startOf("day").toISOString();
    const [hours, minutes] = timeDeadline.split(":");
    if (moment().isAfter(moment().hours(hours).minutes(minutes))) {
      return moment().endOf("day").toISOString();
    }

    return moment().startOf("day").toISOString();
  }, [timeDeadline]);

  return (
    <HasOneField
      name={`${name}.publication`}
      placeholder="Suggérer pour une édition"
      aria-label="Sélectionner une édition"
      modelName={modelName}
      query={PublicationsListQuery}
      fragment={PublicationFragment}
      labelSelector={({ date }) => formatEditionLabel(date)}
      labelElementSelector={({ date }) => formatEditionLabel(date)}
      useInitialValue={(v) => v?.id ?? null}
      format={(v) => {
        if (!v) return null;
        return client.readFragment({
          id: `${modelName}:${v.id}`,
          fragment: PublicationFragment,
          fragmentName: PublicationFragment.definitions[0].name.value,
        });
      }}
      parse={(v) => v ?? null}
      searchVariables={{
        type: type === "newsletter" ? "newsletter" : "digitalPublication",
        date: dateFilter,
        [type === "newsletter" ? "newsletterId" : "periodicalId"]: parentId,
      }}
      searchable={false}
      scale="md"
      clearable
    />
  );
};

function isExposurePlannedDateEditable({ exposure, timeSlots }) {
  if (exposure.type === "tag") {
    return timeSlots.length > 0;
  }

  return Boolean(exposure.plannableMode);
}

const ArticleExposureField = ({
  articleExposure,
  correlatedFieldName,
  name,
  timeSlots,
  exposure,
}) => {
  const isExposureOnlySuggested =
    articleExposure.suggested &&
    !articleExposure.fulfilled &&
    !articleExposure.selected;

  const canEditExposurePlannedDate =
    isExposureOnlySuggested &&
    isExposurePlannedDateEditable({ exposure, timeSlots });

  if (canEditExposurePlannedDate)
    return (
      <ArticleExposurePlannedDateField
        exposure={exposure}
        name={`${name}.planning`}
        correlatedFieldName={correlatedFieldName}
      />
    );
  const shouldDisplayPublicationField =
    exposure.type === "periodical" || exposure.type === "newsletter";
  if (isExposureOnlySuggested && shouldDisplayPublicationField)
    return (
      <ArticleExposurePublicationField
        name={name}
        type={exposure.type}
        parentId={
          exposure.type === "periodical"
            ? exposure.periodical?.id
            : exposure.newsletter?.id
        }
        timeDeadline={
          exposure.type === "periodical"
            ? exposure.periodical?.periodicity?.timeDeadline
            : exposure.newsletter?.timeDeadline
        }
      />
    );

  return (
    <ExposureLabel
      label={exposure.label}
      isExposureOnlySuggested={isExposureOnlySuggested}
    />
  );
};

const RecorrelateButton = ({ fieldName }) => {
  const form = useForm();
  return (
    <Tooltip tooltip="Recorréler avec la date de publication">
      <Clickable
        role="button"
        onClick={() => {
          form.change(fieldName, true);
        }}
      >
        <LinkTiltedBroken
          width={16}
          height={16}
          className="text-warning-on-light hover:text-primary-on"
        />
      </Clickable>
    </Tooltip>
  );
};

const ArticleExposureCorrelatedField = forwardRef(({ name }, ref) => {
  const field = useCheckboxField(name);

  return (
    <FieldControl
      ref={ref}
      {...field}
      fieldName={name}
      as={RecorrelateButton}
    />
  );
});

const UpdateArticleExposureSubscription = gql`
  subscription ArticleExposure_exposureField(
    $where: ExposureArticleExposureWhere!
  ) {
    articleExposureUpdated(where: $where) {
      gid
      ...ExposuresField_articleExposures
    }
  }
  ${ExposuresField.fragments.articleExposures}
`;

const ArticleExposure = ({
  article,
  articleExposure,
  articleExposures,
  hasWritePermission,
  index,
  fields,
  name,
  timeSlots,
  values,
}) => {
  useSubscription(UpdateArticleExposureSubscription, {
    variables: {
      where: {
        exposureId: { eq: articleExposure.exposureId },
        articleId: { eq: article.id },
      },
    },
    onData: ({ client, data: { data } }) => {
      const fieldValue = fields.value.at(index);
      if (data.articleExposureUpdated.gid === articleExposure.gid) {
        client.writeFragment({
          id: articleExposure.gid,
          fragment: gql`
            fragment articleExposure on ArticleExposure {
              gid
              ...ExposuresField_articleExposures
            }
            ${ExposuresField.fragments.articleExposures}
          `,
          fragmentName: "articleExposure",
          data: {
            ...articleExposure,
            ...data.articleExposureUpdated,
          },
        });
        fields.update(index, { fieldValue, ...data.articleExposureUpdated });
      }
    },
  });
  const exposure = values.find(
    (node) => Number(node.id) === Number(articleExposure.exposureId),
  );

  const correlatedFieldName = `${name}.correlatedPlannedDateToArticle`;

  return (
    <div
      className="flex h-8 items-center gap-x-4"
      role="listitem"
      aria-label={exposure.label}
    >
      <div className="w-5">
        <ExposureIndicator
          article={article}
          articleExposure={articleExposure}
          exposure={exposure}
        />
      </div>
      <div className="w-full">
        <ArticleExposureField
          articleExposure={articleExposure}
          correlatedFieldName={correlatedFieldName}
          name={name}
          timeSlots={timeSlots}
          exposure={exposure}
        />
      </div>
      {articleExposure.correlatedPlannedDateToArticle === false &&
      !articleExposure.fulfilled ? (
        <div className="w-8">
          <ArticleExposureCorrelatedField name={correlatedFieldName} />
        </div>
      ) : null}
      {hasWritePermission ? (
        <div className="w-8">
          <RemoveButton
            aria-label="Supprimer l’exposition"
            article={article}
            articleExposure={articleExposure}
            articleExposures={articleExposures}
            correlatedFieldName={correlatedFieldName}
            title="Supprimer l’exposition"
          />
        </div>
      ) : null}
    </div>
  );
};

export const ArticleExposures = memo(({ article, articleExposures }) => {
  const { data } = useSafeQuery(ExposuresQuery);

  if (!data) {
    return null;
  }

  const articleExposuresIds = articleExposures.map(
    ({ exposureId }) => exposureId,
  );

  return (
    <>
      <FormLabel>Expositions</FormLabel>
      <div className="flex gap-1">
        {articleExposuresIds.map((id) => (
          <ExposureChip
            key={id}
            id={id}
            article={article}
            articleExposures={articleExposures}
            values={data.connection.nodes}
          />
        ))}
      </div>
    </>
  );
});

const ExposureChip = ({ id, article, articleExposures, values, children }) => {
  const articleExposure = formatArticleExposure(articleExposures, id);
  const exposure = values.find((node) => Number(node.id) === Number(id));

  if (!exposure) return null;

  return (
    <Chip key={id}>
      <ExposureIndicator
        article={article}
        articleExposure={articleExposure}
        exposure={exposure}
      />
      {typeof children === "function" ? children(articleExposure) : children}
    </Chip>
  );
};
