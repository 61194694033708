import { gql } from "@apollo/client";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert } from "swash/Alert";
import { Button } from "swash/Button";
import { SegmentedControl } from "swash/controls/SegmentedControl";

import { ForecastInsightsChart } from "@/components/charts/ForecastInsightsChart";
import { valueFormatter } from "@/components/charts/utils";
import { useAmplitude } from "@/containers/Amplitude";
import { useSafeQuery } from "@/containers/Apollo";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { HasPermission } from "@/containers/User";
import { AnalyticsGroup } from "@/containers/article/panels/analytics/Group";

const MetricDeviceFragment = gql`
  fragment MetricDeviceFragment on MetricsDevice {
    desktop
    mobile
    app
  }
`;

const MetricsTotalsFragment = gql`
  fragment MetricsTotalsFragment on MetricsTotals {
    overall
    total
  }
`;

const InsightDataDeviceFragment = gql`
  fragment InsightDataDeviceFragment on InsightDataDevice {
    totals {
      ...MetricsTotalsFragment
      ...MetricDeviceFragment
    }
    aggregateDataPoints {
      date
      ...MetricDeviceFragment
    }
  }
  ${MetricsTotalsFragment}
  ${MetricDeviceFragment}
`;

const ArticleConversionsQuery = gql`
  query AnalyticsPanelForecastInsightsConversions_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        conversions(input: $input) {
          ...InsightDataDeviceFragment
        }
      }
    }
  }
  ${InsightDataDeviceFragment}
`;

const ArticlePageViewsQuery = gql`
  query AnalyticsPanelForecastInsightsPageViews_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        pageViews(input: $input) {
          ...InsightDataDeviceFragment
        }
      }
    }
  }
  ${InsightDataDeviceFragment}
`;

const ArticleWebTrafficSourcesQuery = gql`
  query AnalyticsPanelForecastInsightsWebTrafficSources_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        webTrafficSources(input: $input) {
          totals {
            ...MetricsTotalsFragment
            searchRate
            socialRate
            internalRate
            darkRate
            othersRate
          }
          aggregateDataPoints {
            date
            search
            social
            internal
            dark
            others
          }
        }
      }
    }
  }
  ${MetricsTotalsFragment}
`;

const ArticleEngagementQuery = gql`
  query AnalyticsPanelForecastInsightsEngagement_article($id: Int!) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        engagement {
          averageReadingDuration
          averageReadingRate
          share
          subscriberTrafficRate
        }
      }
    }
  }
`;

const Indicator = ({ value, title, unit }) => {
  return (
    <div className="flex items-center justify-between border-secondary-border-light py-2 [&:not(:last-child)]:border-b">
      <h3 className="text-secondary-on">{title}</h3>
      <p className="font-semibold text-info-on">
        {valueFormatter({ value, unit })}
      </p>
    </div>
  );
};

const Engagement = ({ articleId }) => {
  const { data } = useSafeQuery(ArticleEngagementQuery, {
    variables: { id: articleId },
  });
  const insights = data?.article.forecastInsights.engagement;
  return (
    <AnalyticsGroup title="Engagement">
      <div className="px-2 font-accent">
        <Indicator
          title="Part de trafic abonnés"
          value={insights?.subscriberTrafficRate}
          unit="percent"
        />
        <Indicator
          title="Temps de lecture moyen"
          value={insights?.averageReadingDuration}
          unit="duration"
        />
        <Indicator
          title="Pourcentage lu de l’article"
          value={insights?.averageReadingRate}
          unit="percent"
        />
        <Indicator title="Nombre de partages" value={insights?.share} />
      </div>
    </AnalyticsGroup>
  );
};

const config = {
  yLimit: [0, "dataMax"],
  height: 140,
  animationDuration: 500,
  groups: {
    conversions: {
      title: "Conversions",
      yAxisLabel: "conversions",
      query: ArticleConversionsQuery,
      keys: ["app", "desktop", "mobile"],
    },
    pageViews: {
      title: "Pages vues uniques",
      yAxisLabel: "pages vues",
      query: ArticlePageViewsQuery,
      keys: ["app", "desktop", "mobile"],
    },
    webTrafficSources: {
      title: "Sources de trafic Web",
      yAxisLabel: "pages vues",
      query: ArticleWebTrafficSourcesQuery,
      keys: ["search", "social", "internal", "dark", "others"],
      displayTotal: false,
    },
  },
  labels: [
    {
      key: "app",
      color: "#FF6B6E",
      tailwind: "border-red-border bg-red-bg",
      legend: "App",
    },
    {
      key: "desktop",
      color: "#5849E2",
      tailwind: "border-purple-border-strong bg-purple-bg",
      legend: "Web (desktop)",
    },
    {
      key: "mobile",
      color: "#5EBCD5",
      tailwind: "border-blue-border bg-blue-bg",
      legend: "Web (mobile)",
    },
    {
      key: "others",
      color: "#5EBCD5",
      tailwind: "border-blue-border bg-blue-bg",
      legend: "Autres",
      hint: "Part du trafic web en provenance d’autres sources : newsletters, partenaires...",
    },
    {
      key: "social",
      color: "#42C798",
      tailwind: "border-green-border bg-green-bg",
      legend: "Réseaux sociaux",
      hint: "Part du trafic web en provenance des réseaux sociaux : Facebook, X...",
    },
    {
      key: "dark",
      color: "#5849E2",
      tailwind: "border-purple-border bg-purple-bg",
      legend: "Dark social",
      hint: "Part du trafic web de provenance indéterminée, souvent liée à des partages par messagerie",
    },
    {
      key: "internal",
      color: "#FF6B6E",
      tailwind: "border-red-border bg-red-bg",
      legend: "Recirculation",
      hint: "Part du trafic web en provenance d’une autre page du site : homepage, article...",
    },
    {
      key: "search",
      color: "#F0AF60",
      tailwind: "border-orange-border bg-orange-bg",
      legend: "Moteurs",
      hint: "Part du trafic web en provenance des moteurs de recherche : Google, Bing, Yahoo....",
    },
  ],
};

function useLogEventPeriodChange() {
  const { logEvent } = useAmplitude();
  const { pathname } = useLocation();

  const source = useMemo(() => pathname.split("/")[1], [pathname]);

  return useCallback(
    ({ period, chart }) =>
      logEvent("statsPanel:chart:changePeriod", {
        source,
        chart,
        period,
      }),
    [logEvent, source],
  );
}

const Metric = memo(({ dataKey, articleId }) => {
  const logEventPeriodChange = useLogEventPeriodChange();
  const [interval, setInterval] = useState("LAST_24_HOURS");

  const refs = useRef({ dataKey, logEventPeriodChange });

  const handleIntervalChange = useCallback((interval) => {
    const { dataKey, logEventPeriodChange } = refs.current;
    logEventPeriodChange({ chart: dataKey, interval });
    setInterval(interval);
  }, []);

  const { groups, ...conf } = config;
  const group = groups[dataKey];
  const ctxConfig = {
    ...conf,
    labels: config.labels.filter((l) => group.keys.includes(l.key)),
    group,
  };

  const queryResult = useSafeQuery(ctxConfig.group.query, {
    variables: { id: articleId, input: { interval } },
    fetchPolicy: "network-only",
  });

  const data = queryResult.data ?? queryResult.previousData;
  const article = data?.article;
  const insight = article?.forecastInsights[dataKey];

  return (
    <AnalyticsGroup
      title={ctxConfig.group.title}
      value={insight?.totals.overall}
    >
      <SegmentedControl
        className="my-2"
        value={interval}
        scale="sm"
        onChange={handleIntervalChange}
      >
        <Button id="LAST_24_HOURS">24 heures</Button>
        <Button id="LAST_7_DAYS">7 jours</Button>
        <Button id="LAST_30_DAYS">30 jours</Button>
        <Button id="SINCE_PUBLICATION">Total</Button>
      </SegmentedControl>
      <ForecastInsightsChart
        data={insight}
        config={ctxConfig}
        interval={interval}
        loading={queryResult.loading}
        article={article}
      />
    </AnalyticsGroup>
  );
});

const ForecastEnabledQuery = gql`
  query ArticleAnalyticsPanel_thirdPartyService {
    thirdPartyService(key: forecast) {
      enabled
    }
  }
`;

const MissingForecastData = memo(() => {
  return (
    <Alert>
      Les données provenant de Forecast sont incomplètes et ne peuvent être
      affichées.
    </Alert>
  );
});

export function ForecastInsights({ articleId }) {
  const { data } = useSafeQuery(ForecastEnabledQuery);
  if (!data) return null;
  return (
    <HasPermission permission="stats:full">
      {data.thirdPartyService.enabled && (
        <ErrorBoundary
          key={articleId}
          component={MissingForecastData}
          shouldCaptureError={() => false}
        >
          {Object.keys(config.groups).map((key) => {
            return <Metric key={key} dataKey={key} articleId={articleId} />;
          })}
          <Engagement articleId={articleId} />
        </ErrorBoundary>
      )}
    </HasPermission>
  );
}
