import camelCase from "camelcase";
import type { IconBaseProps, IconType } from "react-icons";
import * as icons from "swash/Icon";

export const NamedIcon: React.FC<{ name: string } & IconBaseProps> = ({
  name,
  ...props
}) => {
  if (!name) {
    return null;
  }
  const iconName = camelCase(name, { pascalCase: true });

  const Icon =
    (icons as Record<string, IconType>)[iconName] ?? icons.IoHelpCircle;

  return (
    <Icon
      width={props.width ?? props.size}
      height={props.height ?? props.size}
      {...props}
    />
  );
};
