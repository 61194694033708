/* eslint-disable no-param-reassign */
import clsx from "clsx";
import { memo, useLayoutEffect, useMemo, useState } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import { Image } from "@/components/Image";

export const ImageCropPreview = memo(
  ({
    imgSize,
    imgProps,
    region,
    onRatioChange,
    onSelectionMouseDown,
    className,
    children,
    ...props
  }) => {
    const onRatioChangeRef = useLiveRef(onRatioChange);
    const [loaded, setLoaded] = useState(false);
    const [displaySize, setDisplaySize] = useState(null);
    const imgRef = useResizeObserver((entry) => {
      setDisplaySize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    });
    const ratio = useMemo(() => {
      if (!loaded || !displaySize) return null;
      return imgSize.width / displaySize.width;
    }, [loaded, displaySize, imgSize.width]);
    const selection = useMemo(
      () =>
        ratio && region
          ? {
              top: region.top / ratio,
              left: region.left / ratio,
              width: region.width / ratio,
              height: region.height / ratio,
            }
          : null,
      [ratio, region],
    );
    useLayoutEffect(() => {
      if (onRatioChangeRef.current) {
        onRatioChangeRef.current(ratio);
      }
    }, [onRatioChangeRef, ratio]);

    return (
      <div className={clsx(className, "relative overflow-hidden")} {...props}>
        <Image
          ref={imgRef}
          {...imgProps}
          style={{
            ...imgProps.style,
            filter: "blur(3px) grayscale(1) brightness(.5)",
          }}
          onLoad={() => {
            setLoaded(true);
          }}
        />
        {children ? children({ selection }) : null}
        {selection && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="absolute overflow-hidden border border-white"
            style={{
              left: Math.floor(selection.left),
              top: Math.floor(selection.top),
              width: Math.floor(selection.width),
              height: Math.floor(selection.height),
            }}
            onMouseDown={onSelectionMouseDown}
          >
            <Image
              {...imgProps}
              className="absolute max-h-none max-w-none"
              style={{
                top: -Math.floor(selection.top),
                left: -Math.floor(selection.left),
                height: displaySize.height,
                width: displaySize.width,
              }}
              draggable="false"
            />
          </div>
        )}
      </div>
    );
  },
);
