import { x } from "@xstyled/styled-components";
import { memo } from "react";
import { Navigate } from "react-router-dom";
import { DialogLayout } from "swash/DialogLayout";
import { IoEyeOff } from "swash/Icon";
import { PanelBody } from "swash/Panel";

import { Backdrop } from "@/components/Backdrop";
import {
  useHasExperimentalFeature,
  useHasLevelAccess,
  useHasPermission,
} from "@/containers/User";

export const NoPermissionsContent = memo(({ permissions = [] }) => {
  const permissionsArr =
    typeof permissions === "string" ? [permissions] : permissions;

  return (
    <>
      <x.div display="inline-block" as={IoEyeOff} mb={3} h={27} w={27} />
      <x.div mb={5} fontWeight="600" fontSize="xl" fontFamily="accent">
        Droits insuffisants
      </x.div>
      <x.div mb={5} w={27} h={3} backgroundColor="blue-light" />
      <x.div mb={3} fontWeight="600" fontFamily="accent">
        Vous ne disposez pas des droits nécessaires pour accéder à ce contenu
      </x.div>
      {permissionsArr.length > 0 && (
        <x.div mb={5}>
          {permissionsArr.length > 1 ? (
            <>
              Les permissions{" "}
              <i>
                {new Intl.ListFormat("fr", {
                  style: "long",
                  type: "conjunction",
                }).format(permissionsArr)}
              </i>{" "}
              sont nécessaires pour y accéder.
            </>
          ) : (
            <>
              La permission <i>{permissionsArr[0]}</i> est nécessaire pour y
              accéder.
            </>
          )}
        </x.div>
      )}
    </>
  );
});

export const NoPermissions = memo(({ permissions = [] }) => {
  return (
    <>
      <Backdrop zIndex="content-backdrop" />
      <DialogLayout style={{ width: 800 }} className="py-8">
        <PanelBody className="flex flex-col items-center justify-center">
          <NoPermissionsContent permissions={permissions} />
        </PanelBody>
      </DialogLayout>
    </>
  );
});

const BlockIfNotEnoughPermissions = ({ permissions, children }) => {
  const hasPermission = useHasPermission(permissions, { method: "some" });
  if (!hasPermission) {
    return <NoPermissions permissions={permissions} />;
  }

  return children;
};

const BlockIfNotEnoughLevelAccess = ({ level, children }) => {
  const hasLevelAccess = useHasLevelAccess(level);
  if (!hasLevelAccess) {
    return <NoPermissions />;
  }

  return children;
};

const BlockIfNotEnoughLevelAccessOrNotEnoughPermissions = ({
  level,
  permissions,
  children,
}) => {
  const hasPermission = useHasPermission(permissions, { method: "some" });
  const hasLevelAccess = useHasLevelAccess(level);
  return hasLevelAccess || hasPermission ? children : <NoPermissions />;
};

export const blockIfNotAllowed =
  ({ level, permissions, feature }) =>
  (Component) =>
  (props) => {
    // check feature first then level and permissions
    if (feature) {
      const hasFeature = useHasExperimentalFeature(feature);
      if (!hasFeature) {
        return <Navigate to={"/"} />;
      }
    }
    if (level && permissions) {
      return (
        <BlockIfNotEnoughLevelAccessOrNotEnoughPermissions
          level={level}
          permissions={permissions}
          feature={feature}
        >
          <Component {...props} />
        </BlockIfNotEnoughLevelAccessOrNotEnoughPermissions>
      );
    }

    if (level) {
      return (
        <BlockIfNotEnoughLevelAccess level={level}>
          <Component {...props} />
        </BlockIfNotEnoughLevelAccess>
      );
    }

    if (permissions) {
      return (
        <BlockIfNotEnoughPermissions permissions={permissions}>
          <Component {...props} />
        </BlockIfNotEnoughPermissions>
      );
    }

    return <Component {...props} />;
  };
