import {
  Disclosure,
  DisclosureContent,
  useDisclosureState,
} from "ariakit/disclosure";
import clsx from "clsx";
import { camelCase } from "lodash-es";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoChevronForward } from "swash/Icon";
import { Link } from "swash/Link";
import { Tooltip } from "swash/Tooltip";

import { useSetToggledAnalytics, useToggledAnalytics } from "./TogglerProvider";

const ANALYTICS_DURATION_UPDATE = 60000; // 1 minute
const MAX_ITEMS = 3;

function formatValue(value) {
  if (typeof value !== "number") return value;
  return value.toLocaleString();
}
function formatForLocalStorage(value) {
  return camelCase(value);
}
export function AnalyticsGroup({ title, value, children }) {
  const toggledAnalytics = useToggledAnalytics();
  const setToggledAnalytics = useSetToggledAnalytics();
  const stateFromStorage =
    toggledAnalytics && toggledAnalytics[formatForLocalStorage(title)];
  const disclosure = useDisclosureState({
    open: typeof stateFromStorage === "boolean" ? stateFromStorage : true,
  });
  function handleClick() {
    setToggledAnalytics({
      ...toggledAnalytics,
      [formatForLocalStorage(title)]: !disclosure.open,
    });
  }
  return (
    <div className="rounded-md border border-grey-border-light p-4">
      <Disclosure state={disclosure} onClick={handleClick}>
        {(disclosureProps) => (
          <div
            className="user-select-none group grid cursor-pointer items-center gap-2 font-semibold"
            style={{
              gridTemplateColumns: "max-content minmax(0, 1fr) max-content",
            }}
            {...disclosureProps}
          >
            <IoChevronForward className="transition group-aria-[expanded=true]:rotate-90" />
            <h3>{title}</h3>
            {value != null ? (
              <span className="font-bold text-primary-on">
                {formatValue(value)}
              </span>
            ) : null}
          </div>
        )}
      </Disclosure>
      <DisclosureContent state={disclosure} className="pt-2">
        {children}
      </DisclosureContent>
    </div>
  );
}

export function AnalyticsRow({ label, value, labelTooltip }) {
  const isNaturalValue =
    typeof value === "string" || Array.isArray(value) || (value && value > 0);
  return (
    <div className="flex gap-2 border-b border-grey-border-light py-2 last:border-b-0 last:pb-0">
      {labelTooltip ? (
        <Tooltip placement="left" tooltip={labelTooltip}>
          <div className="flex-1">
            {isNaturalValue ? (
              label
            ) : (
              <span className="text-grey-on">{label}</span>
            )}
          </div>
        </Tooltip>
      ) : (
        <div className="flex-1">
          {isNaturalValue ? (
            label
          ) : (
            <span className="text-grey-on">{label}</span>
          )}
        </div>
      )}
      <div className="shrink-0">
        {isNaturalValue ? (
          <strong>{formatValue(value)}</strong>
        ) : (
          <span className="text-grey-on">—</span>
        )}
      </div>
    </div>
  );
}

const ShowMoreButton = ({ onClick }) => {
  return (
    <div>
      <Link asChild className="text-sm">
        <button
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
        >
          Voir plus...
        </button>
      </Link>
    </div>
  );
};

export const AnalyticsList = ({ items, format, field }) => {
  const [showMore, setShowMore] = useState(items.length > MAX_ITEMS);

  const handleShowMore = useCallback(() => setShowMore(false), []);
  const showedItems = useMemo(
    () => (showMore ? items.slice(0, MAX_ITEMS) : items),
    [items, showMore],
  );

  if (!items.length) return null;

  return (
    <>
      <ul
        className={clsx("ml-6 flex flex-col gap-2 pt-2", !showMore && "pb-2")}
      >
        {showedItems.map((item, index) => {
          const value = field ? item[field] : item;
          const key = item?.id ?? `analytics-item-${index}`;

          return (
            <li key={key} className="list-disc text-sm">
              {format(value)}
            </li>
          );
        })}
      </ul>
      {showMore ? <ShowMoreButton onClick={handleShowMore} /> : null}
    </>
  );
};

function getTotalDuration(list) {
  const durationNumber = list.reduce((totalDuration, stagingArea) => {
    if (!stagingArea.articleExposurePeriods?.length) return totalDuration;

    const stagingAreaDuration = stagingArea.articleExposurePeriods.reduce(
      (acc, period) => {
        const { from, to } = period;
        if (!from) return acc;

        const fromTz = moment(from).tz("Europe/Paris");
        if (!to) {
          return acc + moment.tz("Europe/Paris").diff(fromTz);
        }

        const toTz = moment(to).tz("Europe/Paris");
        return acc + toTz.diff(fromTz);
      },
      0,
    );

    return totalDuration + stagingAreaDuration;
  }, 0);

  return durationNumber;
}

export function AnalyticsDuration({ list }) {
  const [totalDuration, setTotalDuration] = useState(getTotalDuration(list));

  useEffect(() => {
    const id = setInterval(
      () => setTotalDuration(getTotalDuration(list)),
      ANALYTICS_DURATION_UPDATE,
    );
    return () => clearInterval(id);
  }, [list]);

  const totalHumanizedDuration = useMemo(
    () => moment.duration(totalDuration).humanize(),
    [totalDuration],
  );

  return totalDuration > 0 ? (
    <div className="text-sm text-grey-on">
      Durée d’exposition :{" "}
      <span className="font-semibold">{totalHumanizedDuration}</span>
    </div>
  ) : null;
}

export const AnalyticsListTitle = ({ title, duration }) => {
  return (
    <div className="flex items-baseline justify-between">
      <span className="font-accent font-semibold">{title}</span>
      {duration}
    </div>
  );
};

export const AnalyticsListLabel = ({ label, empty }) => (
  <div className="flex items-center gap-2">
    <div
      className={clsx(
        "whitespace-nowrap font-accent text-sm font-semibold",
        empty && "text-grey-on-light",
      )}
    >
      {label}
    </div>
    <hr className="w-full border-t border-t-grey-border-light" />
  </div>
);
