import { gql } from "@apollo/client";

import { ImageFixedFragment } from "@/components/Image";

const BaseImageNodeFragment = gql`
  fragment BaseImageNodeFragment on Image {
    expired
    credit
    webAuthorized
    caption
    url
    thumbnail: fixed(width: 72, height: 52) {
      ...ImageFixedFragment
    }
  }
  ${ImageFixedFragment}
`;

export const ImageNodeFragment = {
  image: gql`
    fragment ImageNode_image on Image {
      id
      ...BaseImageNodeFragment
    }
    ${BaseImageNodeFragment}
  `,
  image__expanded: gql`
    fragment ImageNode_image__expanded on Image {
      id
      ...BaseImageNodeFragment
      expanded: fixed(width: 666, height: 444) {
        ...ImageFixedFragment
      }
    }
    ${ImageFixedFragment}
    ${BaseImageNodeFragment}
  `,
};
