import { memo } from "react";

import { getCellStyle } from "../List";
import {
  CellType,
  NodeProvider,
  NodeProviderComponent,
  NodeRowType,
  RunnableInteraction,
} from "../ListState";
import { ListCell, ListRow } from "../NudeList";

type NodeRowProps<TNode> = {
  row: NodeRowType<TNode>;
  cells: CellType<TNode>[];
  interactions: RunnableInteraction<TNode>[];
  provider: NodeProvider<TNode> | null;
  onActiveChange: (node: TNode) => void;
  checkViewed: (node: TNode) => boolean;
};

const NodeCell = <TNode,>({
  cell,
  node,
}: {
  cell: CellType<TNode>;
  node: NodeRowType<TNode>["node"];
}) => {
  const visible = cell.useIsVisible ? cell.useIsVisible() : true;
  const selected = cell.useIsHighlighted?.() ?? false;
  if (!visible) return null;

  return (
    <ListCell
      key={cell.key}
      style={{
        ...getCellStyle(cell),
        ...(selected && { paddingTop: "0.25rem", paddingBottom: "0.25rem" }),
      }}
      className={
        selected ? "m-1 rounded bg-grey-bg-hover-transparent font-semibold" : ""
      }
    >
      {cell.render({ node })}
    </ListCell>
  );
};

const NodeRowWrapped = <TNode,>(props: NodeRowProps<TNode>) => {
  const { row, checkViewed } = props;

  const viewed = checkViewed(row.node);

  return (
    <NodeProviderComponent node={row.node} provider={props.provider}>
      <ListRow
        first={row.first}
        last={row.last}
        active={row.active}
        onActive={() => props.onActiveChange(row.node)}
        hidden={row.hidden}
        viewed={viewed}
      >
        {props.interactions.map((effect) => {
          const Component = effect.component;
          return <Component key={effect.key} node={row.node} />;
        })}
        {props.cells.map((cell) => {
          return <NodeCell key={cell.key} cell={cell} node={row.node} />;
        })}
      </ListRow>
    </NodeProviderComponent>
  );
};

export const NodeRow = memo(NodeRowWrapped) as typeof NodeRowWrapped;

export const estimateNodeRowSize = () => {
  return 67;
};
