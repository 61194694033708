import { createContext, useContext, useEffect, useState } from "react";

type SetValues = (filters: any) => any;

const ValuesContext = createContext<{ setValues?: SetValues; values?: any }>(
  {},
);

export type SearchFormProviderProps = {
  state: { setValues: SetValues; values: any };
  children: React.ReactNode;
};

export const SearchFormProvider = (props: SearchFormProviderProps) => {
  const { setValues, values } = props.state;
  return (
    <ValuesContext.Provider value={{ setValues, values }}>
      {props.children}
    </ValuesContext.Provider>
  );
};

export const useSetValues = () => {
  const context = useContext(ValuesContext);
  return context.setValues;
};

export const useValue = (fieldName: string) => {
  const context = useContext(ValuesContext);
  const value = context.values[fieldName];

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return fieldValue;
};
